export class AudioAmbianceGroup {
  collection = [];
  audio;

  constructor(audio) {
    this.audio = audio;
  }

  onStartAudioOnGesture() {}

  add(ambiance) {
    if (!ambiance) {
      return;
    }

    if (this.collection.filter((each) => each === ambiance).length > 0) {
      return;
    }

    var to_soundscape = ambiance.get_soundscape();

    this.loadSoundscape(to_soundscape);
    this.playSoundscape(to_soundscape);
    this.collection.push(ambiance);
  }

  transition(ambiance_from, ambiance_to) {
    this.removeAmbianceFromCollection(ambiance_from);

    var from_soundscape = ambiance_from.get_soundscape();
    var to_soundscape = ambiance_to.get_soundscape();

    from_soundscape.forEach((each) => {
      var found = each.findInArray(to_soundscape);
      if (found) {
      } else {
        this.audio.connectedStems.stopStem(each);
      }
    });
    ambiance_from.onListenLocationExit(this);

    to_soundscape.forEach((each) => {
      var found = each.findInArray(from_soundscape);
      if (found) {
        this.audio.connectedStems.updateStem(each);
      } else {
        this.audio.connectedStems.loadStem(each);
        this.audio.connectedStems.playStem(each);
      }
    });

    this.collection.push(ambiance_to);
  }

  removeAmbianceFromCollection(ambiance) {
    const index = this.collection.indexOf(ambiance);
    if (index > -1) {
      this.collection.splice(index, 1);
    }
  }

  remove(ambiance) {
    if (!ambiance) {
      return;
    }
    var from_soundscape = ambiance.get_soundscape();
    this.stopSoundscape(from_soundscape);
    this.removeAmbianceFromCollection(ambiance);
    ambiance.onListenLocationExit(this);
  }

  playSoundscape(soundscape) {
    soundscape.forEach((stem) => {
      this.audio.connectedStems.playStem(stem);
    });
  }

  stopSoundscape(soundscape) {
    soundscape.forEach((stem) => {
      this.audio.connectedStems.stopStem(stem);
    });
  
  }

  loadSoundscape(soundscape) {
    soundscape.forEach((stem) => {
      this.audio.connectedStems.loadStem(stem);
    });
  }
}
