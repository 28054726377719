
import { VideoFileDoubleBufferedResource } from './resources/VideoFileDoubleBufferedResource.js';
import { VideoFileResource } from './resources/VideoFileResource.js';
import { VideoFileSingleBufferedResource } from './resources/VideoFileSingleBufferedResource.js';
import { WebApplication } from '../../webApplication.js';
import { ImageResource } from "./resources/ImageResource.js"
import { AudioVisualScriptResource } from './resources/AudioVisualScriptResource.js';


export class VisualElements {
    static DisplayTimeoutMS = 4000;
    obj;
    visual_elements = [];

    static {
    }

    constructor(obj) {
        this.obj = obj;
    }

    get server_file_cache() {
        return this.obj.server_file_cache;
    }
    isAdded(e) {
        const index = this.visual_elements.indexOf(e);
        return index > -1;
    }
    add(e) {
        this.visual_elements.push(e);
        return e;
    }
    remove(e) {
        this.cancelDisplayTimeout(e);
        const index = this.visual_elements.indexOf(e);
        if (index > -1) {
            this.visual_elements.splice(index, 1);
        }
    }

    visual_element_display_timeouts = {};

    onDisplayTimeout(e) {
        this.cancelDisplayTimeout(e);
        console.info(`visual:timeout `);
        e.on_display_timeout();
    }
    startDisplayTimeout(e) {

        this.cancelDisplayTimeout(e);
        //   console.assert(this.visual_element_display_timeouts[e] == undefined);

        var timer = setTimeout(() => {
            this.onDisplayTimeout(e);
        }, VisualElements.DisplayTimeoutMS);
        // console.info(`visual:start:timeout `);

        this.visual_element_display_timeouts[e] = timer;
    }

    cancelDisplayTimeout(e) {
        var timer = this.visual_element_display_timeouts[e];
        //console.assert(timer != undefined);
        if (timer) {
            // console.info(`visual:stop:timeout `);
            clearTimeout(timer);
            delete this.visual_element_display_timeouts[e];

        }
    }

    static ImageVisualResourceType;
    static VideoVisualResourceType;
    static AudioVisualScriptVisualResourceType;
    static visualResourceTypes;

    static initializeResourceTypes(application) {

        VisualElements.visualResourceTypes = [];

        VisualElements.ImageVisualResourceType = ImageResource;
        VisualElements.visualResourceTypes.push(VisualElements.ImageVisualResourceType);

        if (application.getSetting(WebApplication.IsVideoEnabledSettingName)) {
            if (application.getSetting(WebApplication.IsDBVideoEnabledSettingName)) {
                // console.log(`new db video visual element`);
                VisualElements.VideoVisualResourceType = VideoFileDoubleBufferedResource;
            } else if (application.getSetting(WebApplication.IsSBVideoEnabledSettingName)) {
                //  console.log(`new sb video visual element`);
                VisualElements.VideoVisualResourceType = VideoFileSingleBufferedResource;
            } else {
                //  console.log(`new ps video visual element`);
                VisualElements.VideoVisualResourceType = VideoFileResource;
            }
            VisualElements.visualResourceTypes.push(VisualElements.VideoVisualResourceType);
        }

        if (application.getSetting(WebApplication.isAudioVisualScriptsEnabled_SettingName)) {
            VisualElements.AudioVisualScriptVisualResourceType = AudioVisualScriptResource;
            VisualElements.visualResourceTypes.push(VisualElements.AudioVisualScriptVisualResourceType);
        }
    }
}
