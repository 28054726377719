import { InteractiveLayers } from './InteractiveLayers';
import { VisualElement } from '../audio_visual/visual/visualElement';
import { SceneObjects } from './scene_objects';
import { RectangleGeometry } from '../geometry/RectangleGeometry';

export class SceneObject {
  static typename = "object";
  parent;
  json;
  interactiveLayers;
  visual_element;

  constructor(parent, json) {
    this.parent = parent;
    this.json = json;
  }

  get type() {
    return this.json.type;
  }
  get isInventoryItem() {
    return this.json.isInventoryItem;
  }

  initializeFromJson() {

    this.json.resourcePath = [SceneObjects.baseResourcePath, this.type].join('/');

    this.initializePropertiesFromJson();
    this.initializeInteractiveFromJson();
  }

  initializeInteractiveFromJson() {
    if (!this.json.interactive) {
      return;
    }

    this.interactiveLayers = new InteractiveLayers(this);

    this.interactiveLayers.setDefaultLayer(this.json);

    this.interactiveLayers.initializeFromJson();
  }

  initializePropertiesFromJson() {
    for (let each in this.json.variables) {
      if (each.includes(".")) {
        continue;
      }
      var name = each;
      var type = this.json.variables[name];

      var defaultValueSuffix = ".default";
      var defaultValueName = name + defaultValueSuffix;

      if (this.json.variables.hasOwnProperty(defaultValueName)) {
        var defaultValue = this.json.variables[defaultValueName];
      } else {
        if (type == "string") {
          var defaultValue = "";
        } else if (type == "number") {
          var defaultValue = 0;
        }
        else {
          var defaultValue = undefined;
        }
      }

      this[name] = defaultValue;
    }
  }

  get scene() {
    return this;
  }
  get localScene() {
    return this;
  }
  get globalScene() {
    return this.parent.scene;
  }
  getResourcePath() {
    return this.json.resourcePath;
  }
  get sceneGraph() {
    return this.scene.sceneGraph;
  }
  get resources() {
    return this.scene.resources;
  }
  get icanvas() {
    return this.scene.icanvas;
  }
  get scene_graph_node() {
    return this.scene.scene_graph_node;
  }

  // toRect(scaleToCanvas = true) {
  //   if (scaleToCanvas && this.scene_graph_node == undefined) {
  //     return c2.Rect(0, 0, 0, 0);
  //   }


  //   if (this.visual_element.isReady()) {
  //     var visual_rect = this.visual_element.toRect(scaleToCanvas);
  //     if (visual_rect) {
  //       return visual_rect;
  //     }
  //   }

  //   return this.deriveRect(scaleToCanvas);
  // }
  convertEventWithPointToRelativePoint(e) {
    var asMouse = { x: e.offsetX, y: e.offsetY };

    var rect = this.toRect();

    var result = new c2.Point(asMouse.x / rect.w, asMouse.y / rect.h);

    return result;
  }

  // deriveRect(scaleToCanvas = true) {
  //   var defaultW = 256;
  //   var defaultH = 256;

  //   if (scaleToCanvas) {
  //     var hRatio = this.simulation.icanvas.get_width() / defaultW;
  //     var vRatio = this.simulation.icanvas.get_height() / defaultH;
  //     var ratio = Math.min(hRatio, vRatio);
  //     return new c2.Rect(0, 0, defaultW * ratio, defaultH * ratio);
  //   }

  //   return new c2.Rect(0, 0, defaultW, defaultH);
  // }


  startVisualContentGetReady(gettingReadyCallbackCollector) {
    if(!this.visual_element){
      this.visual_element = new VisualElement(this, this.json, this.getResourcePath());
    }
    this.geometry = RectangleGeometry.createGeometryFromJson_Relative(this.json, this, this.scene);

    this.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);
  }
  start() {
    this.visual_element.onReady = (success) => this.onVisualReady(success);
    this.visual_element.onError = (resource) => this.onVisualError(resource);
    this.visual_element.start();
  }

  // start_loaded() {
  // }

  isVisualError = false;

  onVisualError(resource) {
    this.isVisualError = true;
  }

  onVisualReady(resource, success) {
    // collect elements
    //this.canvasElement.draw_order = this.interactive.scene.sceneLayerOrder;


    if (this.isVisualError) {
      this.icanvas.invalidate();
      this.isVisualError = false;
    }

    this.icanvas.try_invalidated_draw();
  }

  stop() {
    this.visual_element.stop();
  }
}
