import { SceneGraphAudioVisualState } from './SceneGraphAudioVisualState';

export class SceneGraphAudioVisualStateSet {
  json;
  av_script_resource;
  constructor(json,av_script_resource) {
    this.json = json;
    this.av_script_resource=av_script_resource;
  }

  initializeFromJson() {
  }

  getDefaultState() {
    for (let each in this.json) {
      var item = this.json[each];
      if (item.isDefault) {
        var obj = new SceneGraphAudioVisualState(item,this);
        obj.initializeFromJson();
        return obj;
      }
    }
  }

  getNewStateObjectByName(propertyName) {
    if (this.json[propertyName]) {
      var data = this.json[propertyName];
      var obj = new SceneGraphAudioVisualState(data,this);
      obj.initializeFromJson();
      return obj;
    } else {
      // Handle cases where the property might not exist
      console.warn(`Property '${propertyName}' not found.`);
      return null; // Or throw an error if appropriate
    }
  }
}

