import { TokenRewardsFromDataContext } from "../tokenEconomy/mod";
import { ModuleIntegration } from "./ModuleIntegration";
//import $ from "jquery";
//import "jquery-ui";
//import "jquery-ui-css";
// const { ObjectId } = require("bson");
import { v4 as uuidv4 } from "uuid";

export class GameCenterModuleIntegration extends ModuleIntegration {
	receivedExit;
	rewards;
	constructor(openModule) {
		super(openModule);
	}

	async windowMessage(event) {
		let body = event.data;
		if (body) {
			const { action, receiptToken } = body; // Return message handler for the current event.
			console.log(`[CABIN][EXTERNAL MODULE] Message received from ${event.origin}:  [action: ${event.data?.action || false}]`);
			console.log(`game center - received ${action} message`);
			if (body.action == "exit") {
				this.sendMessageToModule({ action: "return", receiptToken: `${action}-${receiptToken}` })
				this.openModule.state.canvas.reactivate();
				this.openModule.exit(body);
				this.rewards?.onEnd();
				this.openModule.state.canvas.drawFrame();
				this.openModule = undefined;
				this.rewards = undefined;
			} else if (body.action == "results") {
				var t = new TokenRewardsFromDataContext(this.data_context).tokens_per_win;
				this.rewards?.onDepositTokens(t);

				this.openModule.exit(body);
			} else if (body.action == "ready") {
				this.sendMessageToModule({ action: "return", receiptToken: `${action}-${receiptToken}` })

				var tps = new TokenRewardsFromDataContext(this.data_context).tokens_per_second;
				this.rewards?.onStart(tps);

				var iframeDiv = document.getElementById("fullscreen_iframe");
				var self = this;

				setTimeout(function () {
					document.querySelectorAll(".ui-widget").forEach(each => {
						each.style.border = "none";
						each.style.zIndex = 1;
					});
					document.querySelectorAll(".ui-widget-content").forEach(each => {
						each.style.border = "none";
						each.style.zIndex = 1;
					});
					document.querySelectorAll(".ui-dialog").forEach(each => {
						each.style.border = "none";
						each.style.zIndex = 1;
						each.style.padding="0";
						each.style.overflow="hidden";
					});
					document.querySelectorAll(".ui-dialog-content").forEach(each => {
						each.style.border = "none";
						each.style.zIndex = 1;
						each.style.padding="0";
						each.style.overflow="hidden";
					});
				}, 1000)

				this.openModule.ready();
			} else if (body.action == "return") {
				this.openModule.onStarted(body);
			} else if (body.action == "auth") {
				if (body?.userInfo) { window.postMessage({ action: "auth-result", receiptToken: `${"auth-result"}-${"token"}`, userInfo: body?.userInfo }, window.location.origin); }
				else { await this.openModule.state.auth.authenticate(); }
				// DATA RETURN HERE
			} else if (body.action == 'auth-result') {
				this.authData = body?.userInfo
				this.sendMessageToModule({ action: "home", receiptToken: `${action}-${receiptToken}`, userInfo: body?.userInfo })
			}
		}
	}

	static getUserInfoMessage(isSignedIn = true) {
		let returnValue = {
			action: "userinfo",
			gameReturnKey: undefined,
			receiptToken: "60f64e6cf2",
			signedIn: isSignedIn ? true : false,
			siteSlug: "weartv",
			// userId: isSignedIn ? "64d6bff79678272ff5835866" : undefined,
			userId: isSignedIn ? "LAKESIDE_GUEST_USER" : undefined,
			gameId: isSignedIn ? `${uuidv4()}` : undefined,
			chessUrl: "https://lakeside-auth.nmp.nonprod-sinclairstoryline.com/api/chess"
			// chessUrl: "https://validate.chess.nmp.nonprod-sinclairstoryline.com/api/chess",
		};
		return returnValue;
	}

	static getStartMessage() {
		let returnValue = { action: "start" };
		return returnValue;
	}

	async start(userId) {
		let userInfo = GameCenterModuleIntegration.getUserInfoMessage();
		let start = GameCenterModuleIntegration.getStartMessage();
		if (userId) { userInfo.userId = userId; userInfo.gameId = userId; }
		this.sendMessageToModule(userInfo);
		this.sendMessageToModule(start);
		return;
	}
	addActivationRewards(rewards) { this.rewards = rewards; }
}
