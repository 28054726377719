import { AudioListenerScope } from "./AudioListenerScope";
import { Stem } from "./stem";

export class AudioAmbiance {
  ambianceSet;
  audioContext;
  json;
  stems = [];
  sound_effects_played = []
  parentGetResourcePath;

  get regionName() {
    return this.audioContext.regionName;
  }

  get areaName() {
    return this.audioContext.areaName;
  }

  onListenLocationExit(audio_ambiance_group) {
    for (const each of this.sound_effects_played) {
      if (each.json.isListenerAreaLocal == true) {
        audio_ambiance_group.audio.connectedStems.stopStem(each);
      }
    }
    this.sound_effects_played=[];
  }

  onPlaySoundEffects(stems) {
    this.sound_effects_played.push(...stems);
  }

  collectAmbianceGroup(group, context) { }

  collectConnectedAmbianceGroup(group, context) { }

  constructor(audioContext, json = undefined, parentGetResourcePath = undefined) {
    this.audioContext = audioContext;

    this.parentGetResourcePath = parentGetResourcePath;

    this.json = json || {};

    this.json.stems?.forEach((element) => {
      let stem = this.newStem(element);
      this.stems.push(stem);
    });
  }

  newStem(element) {
    let stem = new Stem(element, this.getResourcePath.bind(this));

    return stem;
  }

  getResourcePath() {
    return this.json.resourcePath || this.parentGetResourcePath();
  }

  get_soundscape() {
    var result = [];

    this.stems.forEach((each) => {
      if (!each.isEnabled) {
        return;
      }
      result.push(each);
    });

    this.ambianceSet.collection.forEach((each_ambiance) => {
      if (each_ambiance === this) {
        return;
      }

      each_ambiance.stems.forEach((each) => {
        if (!each.isEnabled) {
          return;
        }
        if (each.hasRegionGain()) {
          if (result.some((each_result) => each.isEquivilentToStem(each_result))) {
            return;
          }
          result.push(each.toRegionStem());
        }
      });
    });

    return result;
  }
}
