import { Resources } from '../../../resources';
import { SceneGraphAudioVisualScripts } from '../../../sceneGraph/SceneGraphAudioVisualScripts';
import { SceneGraphAudioVisualStateSet } from '../../../sceneGraph/SceneGraphAudioVisualStateSet';
import { AudioVisualScriptResourceCanvasElement } from '../resourceCanvasElements/AudioVisualScriptResourceCanvasElement';

export class AudioVisualScriptResource {
    json_name;
    json;
    video_base_name;
    audio_base_name;
    default_state;
    state_set;
    scripts;

    static canCreateResourceFromJson(json, property) {
        return property == "audio_visual_script" && json[property] !== undefined;
    }

    static canCreateResourceFromJsonObject(json) {
        return json["audio_visual_script"] !== undefined;
    }

    static createResourceFromJson(json, property, path, path2, webapp) {

        var result = new AudioVisualScriptResource();

        result.json_name = json[property].name + "-audio_visual_script";
        var file_path = webapp.resources.getDataFilePathByNameAndExtension(result.json_name, Resources.JsonExtension)

        result.video_base_name = json[property].video_base_name;
        result.audio_base_name = json[property].audio_base_name;

        result.json = webapp.resources.getFetchedJson(file_path);

        result.default_state = json[property].default_state;

        result.state_set = new SceneGraphAudioVisualStateSet(result.json["states"], result);
        result.state_set.initializeFromJson();

        result.scripts = new SceneGraphAudioVisualScripts(result.json["scripts"], result);
        result.scripts.initializeFromJson();

        return result;
    }

    createResourceCanvasElement(vis) {
        return new AudioVisualScriptResourceCanvasElement(vis, this);
    }
}
