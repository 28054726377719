import { ReadonlyProperty } from "./view/propertyEditor";
import { StackLayout } from "./view/stackLayout";
import { AuthorInterface } from "./sceneAuthorInterface/authorInterface";
import { ValueChangedSubscriptions } from './ValueChangedSubscriptions';
import { MemoryDiagnostics } from './MemoryDiagnostics';
import { WebApplicationSettings } from './WebApplicationSettings';

export class WebApplication {

  static isAudioVisualScriptsEnabled_SettingName = "audio visual scripts enabled";
  static isAudioVisualScriptsEnabled_SettingDefaultValue = true;

  static isPreloadConnectedSceneGraphContentEnabled_SettingName = "Preload Connected SceneGraph Content";
  static isPreloadConnectedSceneGraphContentEnabled_SettingDefaultValue = true;

  static isVideoPlayingTimeoutSettingName = "video playing timeout";
  static isVideoPlayingTimeoutSettingDefaultValue = false;

  static isShowMemoryDiagnosticsOverlaySettingName = "Show Memory Diagnostics Overlay";
  static isShowMemoryDiagnosticsOverlaySettingDefaultValue = false;
  //static isMemoryDiagnosticsOverlayTimeoutSettingName = "Memory Diagnostics Overlay Inactivity Timeout Minutes";
  //static isMemoryDiagnosticsOverlayTimeoutSettingDefaultValue=5;
  static isShowConsoleLogOverlaySettingName = "Show Console Log Overlay";
  static isShowConsoleLogOverlaySettingDefaultValue = false;
  static isConsoleLogOverlayTimeoutSettingName = "Console Log Overlay Inactivity Timeout Minutes";
  static isConsoleLogOverlayTimeoutSettingDefaultValue = 5;

  static isShowDebugOverlaySettingName = "Show Debug Overlay";
  static isShowDebugOverlaySettingDefaultValue = true;

  static isShowDetailedDebugOverlaySettingName = "Show Detailed Debug Overlay";
  static isShowDetailedDebugOverlaySettingDefaultValue = false;

  static isDebugOverlayTimeoutSettingName = "Debug Overlay Inactivity Timeout Minutes";
  static isDebugOverlayTimeoutSettingDefaultValue = 0.5;

  static isLoadApplicationLocalStorageSettingName = "isLoadApplicationLocalStorage";
  static isLoadApplicationLocalStorageSettingDefaultValue = true;
  static isSaveApplicationLocalStorageSettingName = "isSaveApplicationLocalStorage";
  static isSaveApplicationLocalStorageSettingDefaultValue = true;
  static isDeleteBeforeSaveApplicationLocalStorageSettingName = "isDeleteBeforeSaveApplicationLocalStorage";
  static isDeleteBeforeSaveApplicationLocalStorageSettingDefaultValue = true;


  static isLoadAccountLocalStorageSettingName = "isLoadAccountLocalStorage";
  static isLoadAccountLocalStorageSettingDefaultValue = false;
  static isSaveAccountLocalStorageSettingName = "isSaveAccountLocalStorage";
  static isSaveAccountLocalStorageSettingDefaultValue = false;
  static isPreCacheAssetsSettingName = "isPreCacheAssets";
  static isPreCacheAssetsSettingDefaultValue = false;
  static isUseRESTSettingName = "isUseREST";
  static isUseRESTSettingDefaultValue = false;

  static IsVerboseLoggingSettingName = "verbose logging";
  static IsVerboseLoggingSettingDefaultValue = true;

  static IsVideoEnabledSettingName = "video enabled";
  static IsVideoEnabledSettingDefaultValue = true;

  static IsDBVideoEnabledSettingName = "double buffered video enabled";
  static IsDBVideoEnabledSettingDefaultValue = false;

  static IsSBVideoEnabledSettingName = "single buffered video enabled";
  static IsSBVideoEnabledSettingDefaultValue = true;

  static IsPSVideoEnabledSettingName = "per-scene video enabled";
  static IsPSVideoEnabledSettingDefaultValue = false;

  static IsAllCanvasVideosSettingName = "per-scene canvas video enabled";
  static IsAllCanvasVideosSettingDefaultValue = false;

  static VideoFramesPerSecondSettingName = "per-scene canvas video Frames Per Second";
  static VideoFramesPerSecondSettingDefaultValue = 30;

  static IsAuthEnabledSettingName = "auth enabled";
  static IsAuthEnabledSettingDefaultValue = true;

  static IsAudioEnabledSettingName = "audio enabled";
  static IsAudioEnabledSettingDefaultValue = true;

  static IsWebpEnabledSettingName = "WebP enabled";
  static IsWebpEnabledSettingDefaultValue = true;

  static IsWebpWebAssemblyEnabledSettingName = "WebP web assembly enabled";
  static IsWebpWebAssemblyEnabledSettingDefaultValue = false;

  settings;
  resources;
  name;
  json;
  versionNumber;
  server;
  memory_diagnostics;
  platform_canvas;

  constructor(resources, name = "", versionNumber = undefined) {
    this.resources = resources;
    this.name = name;
    this.versionNumber = versionNumber;
    this.memory_diagnostics = new MemoryDiagnostics(this);
  }

  setServer(server) {
    this.server = server;
  }

  stroageItemName() {
    return this.resources.combineJsonResourceName(this.name, "application.storage");
  }

  initialize() {
    this.settings = new WebApplicationSettings();
    this.settings.initialize();
    this.settings.addSettings();

    if (this.getSetting(WebApplication.isLoadApplicationLocalStorageSettingName)) {
      this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName(), false);
      this.settings.loadFromStorage(this.json);
    } else {
      this.json = {};
    }
    //this.saveState();
  }

  addSettingSubscription(name, onChanged) {
    this.settings.addSettingSubscription(name, onChanged);
  }
  removeSettingSubscription(name, onChanged) {
    this.settings.removeSettingSubscription(name, onChanged);
  }
  setSetting(property, v, isNotify = true) {
    this.settings.setSetting(property, v, isNotify);
  }
  setDefaultSetting(property, v) {
    this.settings.setDefaultSetting(property, v);
  }
  toggleBooleanSetting(name) {
    this.settings.toggleBooleanSetting(name);
  }
  getSetting(name, onChanged = undefined) {
    return this.settings.getSetting(name, onChanged);
  }
  get settings_json() {
    return this.settings.json.settings;
  }

  saveState() {
    
    if (this.getSetting(WebApplication.isDeleteBeforeSaveApplicationLocalStorageSettingName)) {
      this.resources.deleteLocalStorageItem(this.stroageItemName());
    }

    if (this.getSetting(WebApplication.isSaveApplicationLocalStorageSettingName)) {
      let json_copy = Object.assign({}, this.json, {});
      this.settings.saveToStorage(json_copy);     
      this.resources.setLocalStorageItemAsJson(this.stroageItemName(), json_copy);
    }
  }

  shutdown() {
    this.saveState();
  }

  collectEditableProperties(layout) {
    let version_property = Object.assign(new ReadonlyProperty(), {
      name: "version",
      getValue: () => {
        return this.versionNumber;
      },
    });
    layout.addAsTableRow(version_property.getEditorElements());

    layout.addAsTableRow(
      Object.assign(new ReadonlyProperty(), {
        name: "url",
        getValue: () => {
          return window.location.href;
        },
      }).getEditorElements()
    );
  }

  getAuthorInterfaceName() {
    return this.name;
  }

  createAuthorInterfaceElement() {
    let layout = new StackLayout();
    this.collectEditableProperties(layout);
    AuthorInterface.collectEditablePropertiesFromProperties(this.settings.json.settings, this.settings.json.ui.settings, this.settings.json.default.settings, layout, this.settings.setting_subscriptions);
    return layout.element;
  }

  addAuthorInterfaceElementToTreeview(treeview) {
    let elm = this.createAuthorInterfaceElement();
    treeview.addItem(this.getAuthorInterfaceName(), elm, true);
  }

  initializeExternalModules(state) {

  }
  initializeInternalModules(state) {

  }
  initializeSceneModules(state) {

  }
}
