import { ExternalModule } from './../externalModules/ExternalModule';
import { GameCenterModuleIntegration } from './../externalModules/GameCenterModuleIntegration';
// https://gitlab.com/sinclair-digital/cloud-ninja-dojo/gaming/solitaire-city

/*
https://test-solitaire.nmp.nonprod-sinclairstoryline.com
https://int-solitaire.nmp.nonprod-sinclairstoryline.com
https://qa-solitaire.nmp.nonprod-sinclairstoryline.com
https://solitaire.nmp.sinclairstoryline.com
*/

export class SolitaireExternalModule extends ExternalModule{
    constructor(){
        super();
        this.name="solitaire";
        this.html="https://int-solitaire.nmp.nonprod-sinclairstoryline.com";
        //this.html="http://localhost:3002";
        this.addIntegrations=(openModule)=> openModule.integrations[0] = new GameCenterModuleIntegration(openModule);
		
		//.push(new GameCenterModuleIntegration(openModule));
    }
}