import { WebAudio } from "./webAudio";

export class Stem {
  json;
  parentGetResourcePath;

  constructor(json, parentGetResourcePath) {
    this.json = json || {};

    if (this.json.isEnabled === undefined) {
      this.json.isEnabled = true;
    }
    if (this.json.gain === undefined) {
      this.json.gain = 1;
    }
    if (this.json.isLoop === undefined) {
      this.json.isLoop = true;
    }
    if (this.json.isAutoPlay === undefined) {
      this.json.isAutoPlay = true;
    }
    if (this.json.layer === undefined) {
      this.json.layer = WebAudio.ambiancelayerName;
    }
    if (this.json.region === undefined) {
      this.json.region = {};
    }
    if (this.json.region.gain === undefined) {
      this.json.region.gain = 0;
    }
    this.parentGetResourcePath = parentGetResourcePath;
  }
  findInArray(array) {
    return array.find((each) => each.isEquivilentToStem(this));
  }
  removeFromArray(array) {
    for (let index = 0; index < array.length; index++) {
      if (array[index].isEquivilentToStem(this)) {
        array.splice(index, 1);
      }
    }
  }
  isEquivilentToStem(stem) {
    return /*this.name === stem.name ||*/ this.audioSrc === stem.audioSrc;
  }
  hasRegionGain() {
    return this.json.region?.gain;
  }
  toRegionStem() {
    var result = new Stem({ ...this.json }, this.parentGetResourcePath);
    if (result.hasRegionGain()) {
      result.json.gain = result.json.region.gain;
    }
    return result;
  }
  get name() {
    return this.json.name;
  }
  get isEnabled() {
    return this.json.isEnabled;
  }
  get audioSrc() {
    return this.json.audioSrc;
  }

  getResourcePath() {
    return this.json.resourcePath || this.parentGetResourcePath();
  }

  load(connection) {
    if (!this.isEnabled) {
      return;
    }
    connection.addReference(this);
  }

  unload(connection) {
    if (!this.isEnabled) {
      return;
    }
    connection.removeReference(this);
  }

  play(connection) {
    if (!this.isEnabled) {
      return;
    }
    connection.playFrom(this);
  }

  setGain(connection, amount) {
    if (!this.isEnabled) {
      return;
    }
    this.json.gain = amount;
    connection.setGainFrom(this, amount);
  }

  stop(connection) {
    if (!this.isEnabled) {
      return;
    }
    connection.stopFrom(this);
  }
}
