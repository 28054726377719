/* context

*/
import * as mathHelper from "../../../mathHelper.js";
import { HTML5CanvasElement } from './HTML5CanvasElement.js';
import { OnDrawingParams } from './../OnDrawingParams.js';

let c2 = require("c2.js");

export class ImageCanvasElement extends HTML5CanvasElement {

  constructor(resource, element, resource_canvas_element = undefined, is_invalidating_draw = true) {
    super(resource, element, resource_canvas_element, is_invalidating_draw);
  }

  clip_image_round_cornders(corner_radius_array, draw_rect_array) {
    if (corner_radius_array == undefined) {
      return;
    }
    // https://stackoverflow.com/questions/19585999/canvas-drawimage-with-round-corners

    var x = draw_rect_array[0];
    var y = draw_rect_array[1];
    this.icanvas.ctx.beginPath();
    this.icanvas.ctx.moveTo(x + corner_radius_array[0], y);
    this.icanvas.ctx.lineTo(x + draw_rect_array[2] - corner_radius_array[0], y);
    this.icanvas.ctx.quadraticCurveTo(x + draw_rect_array[2], y, x + draw_rect_array[2], y + corner_radius_array[0]);
    this.icanvas.ctx.lineTo(x + draw_rect_array[2], y + draw_rect_array[3] - corner_radius_array[1]);
    this.icanvas.ctx.quadraticCurveTo(x + draw_rect_array[2], y + draw_rect_array[3], x + draw_rect_array[2] - corner_radius_array[1], y + draw_rect_array[3]);
    this.icanvas.ctx.lineTo(x + corner_radius_array[2], y + draw_rect_array[3]);
    this.icanvas.ctx.quadraticCurveTo(x, y + draw_rect_array[3], x, y + draw_rect_array[3] - corner_radius_array[2]);
    this.icanvas.ctx.lineTo(x, y + corner_radius_array[3]);
    this.icanvas.ctx.quadraticCurveTo(x, y, x + corner_radius_array[3], y);
    this.icanvas.ctx.closePath();
    this.icanvas.ctx.clip();
  }

  draw() {

    if (this.onDrawing) {
      var params = new OnDrawingParams();
      this.onDrawing(params);
      if (params.isCancel) {
        return;
      }
    }

    if (this.resource.isLoading()) {
      return;
    }

    let image_rect = this.resource_canvas_element.geometry.get_absolute_rect_shape();

    if (this.resource.scale) {
      image_rect.w *= this.resource.scale;
      image_rect.h *= this.resource.scale;
    }

    let drawOffsetX = 0;
    let drawOffsetY = 0;

    this.icanvas.ctx.save();

    var corner_radius_array;
    if (this.resource.corner_radius) {
      if (Array.isArray(this.resource.corner_radius)) {
        corner_radius_array = this.resource.corner_radius;
      } else if (typeof this.resource.corner_radius === 'number') {
        corner_radius_array = [this.resource.corner_radius, this.resource.corner_radius, this.resource.corner_radius, this.resource.corner_radius]
      }
    }

    if (this.resource.centerRotateDegrees) {
      // https://stackoverflow.com/questions/3793397/html5-canvas-drawimage-with-at-an-angle
      let r = mathHelper.degreesToRadians(this.resource.centerRotateDegrees);

      this.icanvas.ctx.translate(image_rect.p.x + image_rect.w / 2, image_rect.p.y + image_rect.h / 2);

      this.icanvas.ctx.rotate(r);
      drawOffsetX = -image_rect.w / 2;
      drawOffsetY = -image_rect.h / 2;
    } else {
      drawOffsetX = image_rect.p.x;
      drawOffsetY = image_rect.p.y;
    }

    var json_properties = this.resource.json_properties;
    if (json_properties) {
      if (json_properties["drawAtLocal.rel"] || json_properties["drawAtLocal"]) {
        var xy = json_properties["drawAtLocal.rel"] || json_properties["drawAtLocal"];
        drawOffsetX -= xy[0] * image_rect.w;
        drawOffsetY -= xy[1] * image_rect.h;
        // this.icanvas.ctx.translate(image_rect.p.x - image_rect.w / 2, image_rect.p.y - image_rect.h / 2);
      }
      if (json_properties["drawAtLocal.px"]) {
        var xy = json_properties["drawAtLocal.px"];
        drawOffsetX -= xy[0];
        drawOffsetY -= xy[1];
        // this.icanvas.ctx.translate(image_rect.p.x - image_rect.w / 2, image_rect.p.y - image_rect.h / 2);
      }
    }

    this.width = image_rect.w;
    this.height = image_rect.h;

    var draw_rect_array = [drawOffsetX, drawOffsetY, image_rect.w, image_rect.h]

    if (this.resource.isError) {
      this.icanvas.ctx.rect(draw_rect_array[0], draw_rect_array[1], draw_rect_array[2], draw_rect_array[3]);
    } else {
      this.icanvas.draw_log.push({ message: "image", details: this.element.currentSrc?.split('/').pop() });
      //console.log("visual:image:draw:" + this.element.currentSrc);
      this.clip_image_round_cornders(corner_radius_array, draw_rect_array);
      this.icanvas.ctx.drawImage(this.element, draw_rect_array[0], draw_rect_array[1], draw_rect_array[2], draw_rect_array[3]);
    }

    this.icanvas.ctx.restore();
  }
}
