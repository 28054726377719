import { DrawScope } from "./audio_visual/DrawScope.js";
import { Player } from "./Player.js";
import { SceneGraphSet } from "./sceneGraph/sceneGraphSet.js";
import { SceneGraph } from "./sceneGraph/scene_graph.js";
import { Treeview } from "./view/treeview.js";
import { EmptySceneGraphNode, SceneGraphNode } from "./sceneGraph/scene_graph_node";
import { NavigationMode, SceneInteractionMode } from './input/selectionMode.js';
import { InteractiveEvent } from './sceneGraph/InteractiveEvent.js';
import { ScenePath } from './sceneGraph/ScenePath.js';

export class Simulation {
  icanvas;
  resources;
  sceneGraphSet;
  json;
  account;
  server;
  audio;
  sceneGraphNode;
  player;
  selectionMode;
  state;

  constructor(resources, icanvas, account, server, audio, state) {
    this.state = state;
    this.resources = resources;
    this.icanvas = icanvas;
    this.account = account;
    this.server = server;
    this.sceneGraphSet = new SceneGraphSet(this);

    this.audio = audio;
    this.sceneGraphNode = new SceneGraphNode(this);
    this.selectionMode = new SceneInteractionMode(); //icanvas.platformCanvas.createDefaultSelectionMode();


  }

  is_ux_faded_out(){
    return this.getUXScene()?.isFadedOut()==true;   
  }
  increment_item_set() {

    this.application.memory_diagnostics.increment_item_set();
    this.state.author.console_overlay.increment_item_set();
  }
  get playerSceneGraphNode() {
    return this.player.sceneGraphNode;
  }

  get application() {
    return this.account.application;
  }

  get drawScope() {
    let result = this.account.json_simulation.drawScope;

    if (result == undefined) {
      result = DrawScope.Normal;
    }
    return result;
  }

  set drawScope(v) {
    this.account.json_simulation.drawScope = v;
    this.updateCanvasDrawScope();
  }

  async initialize() {
    this.json = this.resources.getLocalStorageJsonItem(this.stroageItemName());

    await this.sceneGraphSet.initialize();

    this.updateCanvasDrawScope();

    this.player = new Player(this.account, this);

    this.selectionMode.initialize(this);

    await this.sceneGraphNode.initiaize();
    this.sceneGraphNode.event_scene_change_request_complete = (request) => {
      this.scene_change_request_complete(request);
    };

    //this.icanvas.startBatchUpdate();
    //this.sceneGraphSet.initializeGraphNodeScene(this.sceneGraphNode);

    this.player.startChangeLocation(this.player.json.location,true,ScenePath.onlySceneGraphNameFromJsonPath( this.player.json.location));

    //this.player?.initializeSceneGraphNode();

    //this.icanvas.endBatchUpdate();
    // this.sceneGraphNode.initiaizeEnd();

    //this.initializeSceneGraphNode();
  }

  startSceneChange(request,fallback_request_on_error=undefined) {
    //this.sceneGraphSet.tryPopulateDefaultPaths(request.location);
    this.sceneGraphNode.startSceneChange(request,fallback_request_on_error);
  }

  scene_change_request_complete(request) {
    if (request.error) {
      if (ScenePath.areLocationsTheSame(request.location, this.player.json.location)) {
        if (!ScenePath.areLocationsTheSame(request.location, this.player.json.homeLocation)) {
          this.player.startChangeLocation(this.player.json.homeLocation);
        }
      }
    } else {
      this.player.on_scene_change_request_success(request);

      //this.sceneGraphNode.selectNone();
       this.selectionMode.startState();
      //this.selectionMode.transitionState(new NavigationMode(this));
    }
  }

  selectNextSceneInteractive(iteration_amount) {
    this.sceneGraphNode.selectNextSceneInteractive(iteration_amount);
  }
  getSelectedSceneInteractive() {
    return this.sceneGraphNode.getSelectedSceneInteractive();

  }

  // initializeSceneGraphNode() {

  // }

  updateCanvasDrawScope() {
    this.icanvas.drawScope = this.drawScope;
  }

  start() {
  }

  drawFrame(icanvas) {
    this.playerSceneGraphNode.drawFrame(icanvas);
  }

  mousedown(icanvas, e) {
    this.playerSceneGraphNode.mousedown(icanvas, e);
  }
  mouseup(icanvas, e) {
    this.playerSceneGraphNode.mouseup(icanvas, e);
  }
  mousemove(icanvas, e) {
    this.playerSceneGraphNode.mousemove(icanvas, e);
  }

  keydown(icanvas, ievent) {
    if (ievent.e.key == "1") {
      this.toggleDrawScope();
      this.icanvas.invaidate();
    }

    this.selectionMode.keydown(icanvas, ievent);
    if (ievent.isStopPropagation) {
      return;
    }
    this.playerSceneGraphNode.keydown(icanvas, ievent);
  }

  keyup(icanvas, ievent) {
   
    this.selectionMode.keyup(icanvas, ievent);
    if (ievent.isStopPropagation) {
      return;
    }
    this.playerSceneGraphNode.keyup(icanvas, ievent);
  }

  stroageItemName() {
    return this.resources.combineJsonResourceName(this.application.name, this.account.name, "simulation.storage");
  }

  saveState() {
    this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }
  shutdown() {
    this.saveState();
  }

  toggleDrawScope() {
    if (this.drawScope == DrawScope.Normal) {
      this.drawScope = DrawScope.Debug;
    } else {
      this.drawScope = DrawScope.Normal;
    }
  }

  onTouchTap(e) {
    this.playerSceneGraphNode.onTouchTap(e);
  }
  onTouchPan(e) {
    this.playerSceneGraphNode.onTouchPan(e);
  }
  onTouchSwipe(e) {
    this.playerSceneGraphNode.onTouchSwipe(e);
  }
  onTouchDistance(e) {
    this.playerSceneGraphNode.onTouchDistance(e);
  }
  onTouchRotate(e) {
    this.playerSceneGraphNode.onTouchRotate(e);
  }
  onTouchGesture(e) {
    this.playerSceneGraphNode.onTouchGesture(e);
  }

  collectEditableProperties(layout) { }
  getAuthorInterfaceName() {
    return "Simulation";
  }
  createAuthorInterfaceElement() {
  }

  file_dropped(e, files) {
    this.sceneGraph.file_dropped(e, files);
  }
  drag_file(e, files) {
    this.sceneGraph.drag_file(e, files);
  }

  activate(event) {
    this.sceneGraphNode.activate_event(event);
  }
  getActiveScene() {
    return this.sceneGraphNode.getActiveScene();
  }
  getUXScene() {
    return this.sceneGraphNode.getUXScene();
  }
}
