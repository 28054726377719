import { InteractiveArea } from "./InteractiveArea.js";
import { InteractiveInput } from "./InteractiveInput.js";
import { Action } from "./actions/action.js";
import { Actions, newActionFromJson } from "./actions/actions.js";
import { InteractiveLayers } from "./InteractiveLayers.js";
import { UserInterfaceElements } from "../view/userInterfaceElements.js";
import { InteractivePointOfInterest } from './InteractivePointOfInterest.js';

export class Interactive {
  static typename = "interactive";
  parent;
  json;
  area;
  inputs = [];
  actions = [];
  ui;
  points_of_interest;
  isSelected;


  constructor(parent, json) {
    this.parent = parent;
    this.json = json;
  }

  hasPointOfInterest() {
    return this.points_of_interest !== undefined;
  }

  clone() {
    return new Interactive(this.parent, { ...this.json });
  }

  isSelectable() {
    return this.area != undefined && this.area.isSelectable() && !this.getHidden();
  }

  setSelected(value) {
    if (this.isSelected == value) {
      return;
    }

    if (value) {
      for (const each of this.points_of_interest || []) {
        each.start();

        if (each.json["area.visible"] == false) {
          this.area?.visual_element?.setHidden(true);
        }
      }
    } else {
      for (const each of this.points_of_interest || []) {
        each.stop();

        if (each.json["area.visible"] == false) {
          this.area?.visual_element?.setHidden(false);
        }
      }
    }

    this.isSelected = value;
    this.icanvas.invalidate();
  }

  initializeFromJson() {

    if (this.json.layer === undefined) {
      this.json.layer = InteractiveLayers.DefaultLayerJson.name;
    }

    for (let each in this.json) {
      if (each == Action.typename) {
        this.addActionFromJson(this.json[each], this.scene.application);
      } else if (each == InteractiveInput.typename) {
        var i = new InteractiveInput(this, this.json[each]);
        this.inputs.push(i);
      } else if (each == InteractiveArea.typename) {
        this.area = new InteractiveArea(this, this.json[each]);
      } else if (each == Actions.typename) {
        for (let each_action in this.json[each]) {
          this.addActionFromJson(this.json[each][each_action], this.scene.application);
        }
      } else if (each == "ui") {
        this.ui = new UserInterfaceElements(() => { return this.getScreenSpaceAreaRect(); }, this.json[each], this.scene.application);
      }
      else if (each == 'isHidden') {
        this.setHidden(this.json[each]);
      }
    }

    if (this.json[InteractivePointOfInterest.typename]) {
      this.points_of_interest = [new InteractivePointOfInterest(this, this.json[InteractivePointOfInterest.typename])];
    }
    if (this.json[InteractivePointOfInterest.array_typename]) {
      this.points_of_interest = [];
      for (const each of this.json[InteractivePointOfInterest.array_typename]) {
        this.points_of_interest.push(new InteractivePointOfInterest(this, each));
      }
    }

    if (this.area) {
      this.area.initializeFromJson();
    }
    for (const each of this.points_of_interest || []) {
      each.initializeFromJson();
    }
  }

  getHidden() {
    return this.json['isHidden'] == true;
  }
  setHidden(value) {
    this.json['isHidden'] = value;
  }

  get isTemporary() {
    return this.json.isTemporary;
  }
  set isTemporary(v) {
    this.json.isTemporary = v;
  }

  get name() {
    return this.json.name;
  }
  set name(v) {
    this.json.name = v;
  }
  get account() {
    return this.scene.sceneGraph.account;
  }

  get resources() {
    return this.scene.resources;
  }
  get icanvas() {
    return this.scene.icanvas;
  }

  get scene() {
    return this.parent.scene;
  }

  static getNameFromFilename(file) {
    return file.name;
  }

  static newJsonFromFile(file) {
    return {
      name: this.getNameFromFilename(file),
      "interactive.area": {
        "geometry.rectangle": [0.0, 0.0, 0.05, 0.05],
      },
    };
  }

  getScreenSpaceAreaRect() {
    return this.area.getScreenSpaceAreaRect();
  }
  setAreaReletivePosition(point) {
    this.area.setAreaReletivePosition(point);
  }

  addActionFromJson(j, application = undefined) {
    var a = newActionFromJson(j, application);
    if (a) {
      this.actions.push(a);
    }
  }

  getResourcePath() {
    return this.json.resourcePath || this.parent.getResourcePath();
  }

  isVisualContentReady() {
    if (this.area && !this.area.isVisualContentReady()) {
      return false;
    }
    return true;
  }

  startVisualContentGetReady(gettingReadyCallbackCollector) {
    this.area?.startVisualContentGetReady(gettingReadyCallbackCollector);

    if (this.json["points_of_interest.preload"] == true) {
      for (const each of this.points_of_interest || []) {
        each.startVisualContentGetReady(gettingReadyCallbackCollector);
      }
    }

  }

  start() {

    this.area?.start();

    this.ui?.addToDOM();
  }

  stop() {
    this.area?.stop();
    this.ui?.removeFromDOM();

    for (const each of this.points_of_interest || []) {
      each.stop();
    }
  }

  drawFrame(icanvas) {
    if (this.json['isHidden'] == true) {
      return;
    }

    this.area?.drawFrame(icanvas);
    this.ui?.updateDOM();
  }

  isIEventOnInteractiveArea(ievent) {
    if (this.getHidden()) {
      return false;
    }
    return this.area?.isIEventOnInteractiveArea(ievent);
  }

  mousedown(ievent) {
    if (!this.isSelectable()) {
      return;
    }
    this.area?.mousedown(ievent);
  }

  mouseup(ievent) {
    if (!this.isSelectable()) {
      return;
    }
    this.area?.mouseup(ievent);
  }

  mousemove(ievent) {
    if (!this.isSelectable()) {
      return;
    }
    this.area?.mousemove(ievent);

  }

  onMouseUp(ievent, area) {
    if (!this.isSelectable()) {
      return;
    }
    ievent.isStopPropagation = true;

    this.runActions();
  }

  onInteractiveInput(ievent, iinput) {

    ievent.isStopPropagation = true;
    this.runActions();
  }

  runActions() {

    for (let each in this.actions) {
      this.actions[each].run(this);
    }
  }

  runAction(action) {
    const actionStore = this.actions
    this.actions = [action]
    for (let each in this.actions) {
      this.actions[each].run(this);
    }
    this.actions = actionStore;
  }

  apply(command) {
    if (command instanceof Object) {

    }
  }

  keydown(ievent) {
    for (let each in this.inputs) {
      this.inputs[each].keydown(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  keyup(ievent) {
    if (this.getHidden()) {
      return;
    }
    for (let each in this.inputs) {
      this.inputs[each].keyup(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }

  activate(ievent) {
    if (ievent.activate_value == "interactive.input") {
      for (let each in this.inputs) {
        this.inputs[each].activate(ievent);
        if (ievent.isStopPropagation) {
          break;
        }
      }
    }
  }

  onTouchTap(ievent) {
    if (!this.isSelectable()) {
      return;
    }
    this.area?.onTouchTap(ievent);
  }

  onTouchPan(ievent) {
    if (!this.isSelectable()) {
      return;
    }
  }
  onTouchSwipe(ievent) {
    if (!this.isSelectable()) {
      return;
    }
    for (let each in this.inputs) {
      this.inputs[each].onTouchSwipe(ievent);
      if (ievent.isStopPropagation) {
        break;
      }
    }
  }
  onTouchDistance(ievent) {
    if (!this.isSelectable()) {
      return;
    }
  }
  onTouchRotate(ievent) {
    if (!this.isSelectable()) {
      return;
    }
  }
  onTouchGesture(ievent) {
    if (!this.isSelectable()) {
      return;
    }
  }

  collectCanvasElements(result) {
    this.area?.collectCanvasElements(result);
  }
  file_dropped(ievent) {
    if (!this.isSelectable()) {
      return;
    }
  }
  drag_file(ievent) {
    if (!this.isSelectable()) {
      return;
    }
  }
}
