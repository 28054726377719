import { SceneGraphAudioVisualStateBaseComponent } from './SceneGraphAudioVisualStateBaseComponent';
import { SceneGraphAudioVisualStateEventComponent } from './SceneGraphAudioVisualStateEventComponent';

export class SceneGraphAudioVisualState {
    json;
    base_component;
    event_components;
    av_states;

    constructor(json, av_states) {
        this.json = json;
        this.av_states = av_states;
    }

    initializeFromJson() {
        if (this.json.components) {
            this.base_component = new SceneGraphAudioVisualStateBaseComponent(this.json.components.base);
            this.base_component.initializeFromJson();

            if (this.json.components.events) {
                event_components = {};
                for (let each in this.json.components.events) {
                    event_components[each] = new SceneGraphAudioVisualStateEventComponent(this.json.components.events[each]);
                    event_components[each].initializeFromJson();
                }
            }
        }
    }

    loadPlayback(playback_queue) {
        var stems = [];

        if (this.json['video.suffix']) {
            var video_stem = {
                video: this.av_states.av_script_resource.video_base_name + this.json['video.suffix']
            };

            // -4k upgrade

            stems.push(video_stem);
        }

        if (this.json['ambiance.suffix']) {
            var audio_stem = {
                gain: this.json['ambiance.gain'],
                audioSrc: this.av_states.av_script_resource.audio_base_name + this.json['ambiance.suffix']
            };
            stems.push(audio_stem);
        }

        if (this.json['audioAmbiance']) {
            for (const each_audio_stem of this.json['audioAmbiance']['stems']) {
                stems.push(each_audio_stem);
            }
        }

        playback_queue.set_looping_stems(stems)
    }

    get isDefault() {
        return this.json.isDefault;
    }
    get video_suffix() {
        return this.json["video.suffix"];
    }
    get image_suffix() {
        return this.json["image.suffix"];
    }
    get ambiance_suffix() {
        return this.json["ambiance.suffix"];
    }
    get ambiance_gain() {
        return this.json["ambiance.gain"];
    }
    get audioAmbiance() {
        return this.json["audioAmbiance"];
    }
    get transition_from() {
        return this.json["transition.from"];
    }
}
