import { CanvasElement } from "./visual/canvasElements/CanvasElement.js";
import { InteractiveMobileCanvas } from "./interactive_mobile_canvas.js";
import { UpdateContext } from "../update.js";
import { MouseEvent } from "../MouseEvent.js";
import { DrawScope } from "./DrawScope.js";
import { CanvasStack } from "./visual/canvasStack.js";

//import $ from "jquery";
//import "jquery-ui";
//import "jquery-ui-css";

import { dialogOptions, id } from "../modules/ExternalModules.js";
import { InteractiveATSC3TVCanvas, InteractiveBrowserTVCanvas, InteractiveTVCanvas } from './interactive_tv_canvas.js';
import { InteractivePCCanvas } from './InteractivePCCanvas.js';
import { AutoCanvasElementInvalidation } from './AutoCanvasElementInvalidation.js';
import { InteractiveInput } from "../sceneGraph/InteractiveInput.js";
import { NavigateHomeAction } from "../sceneGraph/sceneActions/NavigateHomeAction.js";
import { BackAction } from "../sceneGraph/sceneActions/BackAction.js";
import { InteractiveEvent } from '../sceneGraph/InteractiveEvent.js';
import * as htmlHelper from "../htmlHelper.js";
import { WebApplication } from '../webApplication.js';
import { InteractiveCanvasVideoBuffers } from './InteractiveCanvasVideoBuffers.js';
import { RectangleGeometry } from '../geometry/RectangleGeometry.js';

let c2 = require("c2.js");

export class VideoBufferStatus {
	canplay_count = 0;
	playing_count = 0;
}
export class InteractiveCanvas {
	static viewportId = "viewport";
	viewport;
	canvas;
	ctx;
	platformCanvas;
	components = [];
	window_listeners = {};
	update_context;
	drawScope = DrawScope.Normal;
	elements = [];
	screenElement;
	canvasStack;
	externalModules;
	isInputDisabled;
	isInputEnabledMilliseconds;
	isBatchUpdating;
	batchUpdatePromises;
	frameCount = 0;
	state;
	get State() { return this.state; }
	get application() { return this.state.application; }
	element_invalidate_frequencies = {}
	isDrawDebugText = true;
	debugMessage = "";
	geometry;
	video_buffers = new InteractiveCanvasVideoBuffers();

	constructor(state = undefined) {
		this.intervals = [];
		this.canvasStack = new CanvasStack(this);
		this.state = state;
		this.mode = 'MOVE';
		this.navigateHomeAction = new NavigateHomeAction("home", "Navigate Home");
		this.backAction = new BackAction("back", "Go Back");
		this.keydownCount = { Enter: 0, Backspace: 0 };
		this.keyTimer = { Enter: null, Backspace: null };
	}

	removeAutoInvalidateFrequencyPerSecond(element) {
		for (const eachFrequency in this.element_invalidate_frequencies) {
			var each = this.element_invalidate_frequencies[eachFrequency];
			each.removeElement(element);
			if (each.isEmpty) {
				each.stop();
				delete this.element_invalidate_frequencies[eachFrequency];
			}
		}
	}

	addAutoInvalidateFrequencyPerSecond(element, value) {
		if (value == 0 || value == undefined) {
			return;
		}

		var obj = this.element_invalidate_frequencies[value];

		if (obj == undefined) {
			obj = this.element_invalidate_frequencies[value] = new AutoCanvasElementInvalidation(value, this);

			obj.elements.push(element);
			obj.start();
		} else {
			obj.elements.push(element);
		}
	}


	onActivity() {
		for (let each in this.components) {
			this.components[each].onActivity?.();
		}
	}

	disableInput() {
		this.isInputDisabled = true;
	}
	enableInput() {
		this.isInputDisabled = false;
		this.isInputEnabledMilliseconds = Date.now();
	}
	deactivate() {
		this.viewport.style.display = "none";
	}

	reactivate() {
		this.viewport.style.display = "block";
		this.invalidate();
	}

	setPlatformCanvas(c) {
		this.platformCanvas = c;
	}
	initialize() {
		window.addEventListener("resize", () => {
			// e.preventDefault();
			// e.stopPropagation();
			this.resize();
		});

		this.window_listeners.mousedown = (e) => this.mousedown(e);
		this.window_listeners.mouseup = (e) => this.mouseup(e);
		this.window_listeners.mousemove = (e) => this.mousemove(e);

		var pc_platform = new InteractivePCCanvas();
		var platforms = [
			new InteractiveATSC3TVCanvas(),
			new InteractiveBrowserTVCanvas(),
			new InteractiveMobileCanvas(),
			pc_platform
		];

		for (const each of platforms) {
			each.initialize(this);
		}


		for (const each of platforms) {

			if (each.isPlatform()) {
				this.setPlatformCanvas(each);
				break;
			}
		}

		if (this.platformCanvas == undefined) {
			this.setPlatformCanvas(pc_platform);
		}

		this.window_listeners.keydown = (e) => this.keydown(e);
		this.window_listeners.keyup = (e) => this.keyup(e);

		for (const [key, value] of Object.entries(this.window_listeners)) {
			window.addEventListener(key, value);
		}

		this.viewport = document.getElementById(InteractiveCanvas.viewportId);

		// https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/drop_event
		//
		this.viewport.ondrop = (event) => {
			this.drop_file(event);
		};

		this.viewport.ondragover = (event) => {
			this.drag_file(event);
		};

		this.canvas = document.getElementById("viewport_canvas");
		this.ctx = this.canvas.getContext("2d");
		this.geometry = new RectangleGeometry();
		this.updateCanvasSize(false);

		this.setupUpdate();
		this.screenElement = this.addElement(new CanvasElement());

		this.platformCanvas.initialize_input();
		this.platformCanvas.apply_to_default_settings();
		this.platformCanvas.configure_visual_elements();


		this.initializeVideoBuffers();
	}

	uninitialize() {
		this.platformCanvas.uninitialize();
		window.removeEventListener("resize", this.resize);
		for (const [key, value] of Object.entries(this.window_listeners)) {
			window.removeEventListener(key, value);
		}
		this.intervals.forEach((each) => clearInterval(each));
		this.removeElement(this.screenElement);
	}

	addElement(canvasElement) {
		this.elements.push(canvasElement);
		canvasElement.addedToInteractiveCanvas(this);
		return canvasElement;
	}

	removeElement(canvasElement) {
		let index = this.elements.indexOf(canvasElement);
		if (index >= 0) {
			this.elements[index].removedFromInteractiveCanvas();
			this.elements.splice(index, 1);
		}
	}

	setupUpdate() {
		this.update_context = new UpdateContext();
		this.update_context.start_time = Date.now();
		this.update_context.time = this.update_context.start_time;
		this.update_context.delta = this.update_context.time - this.update_context.start_time;
	}
	updateCanvasSize(notifyElements = true) {
		this.canvas.width = this.viewport.clientWidth;
		this.canvas.height = this.viewport.clientHeight;

		this.geometry.initialize(0, 0, this.viewport.clientWidth, this.viewport.clientHeight);

		if (notifyElements) {
			this.elements.forEach((v) => v.onCanvasResized());
		}
		this.onCanvasResizedForVideoBuffers();
	}

	// toRect() {
	// 	//var result = new c2.Rect(0, 0, this.canvas.clientWidth, this.canvas.clientHeight);
	// 	//return result;
	// 	return this.geometry.shape;
	// }
	convertEventWithPointToRelativePoint(e) {
		var asMouse = { x: e.offsetX, y: e.offsetY };

		var rect = this.geometry.shape;

		var result = new c2.Point(asMouse.x / rect.w, asMouse.y / rect.h);

		return result;
	}

	// update() {
	//   var now = Date.now();
	//   var delta = now - this.update_context.time;

	//   this.update_context.time = now;
	//   this.update_context.delta = delta;
	//   this.update_context.isDrawFrame = false;

	//   this.components.forEach((v) => v?.update(this));

	//   if (this.update_context.isDrawFrame) {
	//     this.drawFrame();
	//   }
	// }

	ClearScreen() {
		this.ctx.clearRect(0, 0, this.ctx.canvas.clientWidth, this.ctx.canvas.clientHeight);
	}

	addComponent(c) {
		this.components.push(c);

		if (c === this.state.author) {

			this.state.author.diagnostics_overlay.set_profile(this.platformCanvas.name);
			this.state.author.diagnostics_overlay.set_brand(this.platformCanvas.brand);
		}
	}

	draw_log;

	drawFrame() {
		var start_now = performance.now();
		this.draw_log = [];
		this.draw_log.push({ message: `draw:frame# ${this.frameCount}` + '\n' });
		this.ClearScreen();

		this.elements.sort((a, b) => (a.draw_order > b.draw_order) ? 1 : -1);
		this.elements.sort((a, b) => (a.draw_order > b.draw_order) ? 1 : -1);

		for (const each of this.elements) {
			if (!each.isHidden) {
				each.draw();
			}
		}

		for (const each of this.components) {
			each.drawFrame(this);
		}

		var end_now = performance.now();
		var ms = end_now - start_now;
		this.draw_log.push({ message: `draw:duration ${ms.toFixed(3)}`, details: "MS" })

		var draw_log_message = this.draw_log.map(each => each.message + (each.details ? '(' + each.details + ')\n' : "")).join("  ");
		//console.log(draw_log_message);
		this.frameCount += 1;

		if (this.isDrawDebugText) {
			if (this.debugMessage) {
				//const displayElement = this.elements[1]?.resource.toRect(this);
				//if (displayElement?.w, displayElement?.h) {
					this.draw_text(this.debugMessage, { x: 20, y: 80 }, 23);
				//}
			}
		}
		this.platformCanvas.drawFrame();
	}

	set_new_debug_message(msg) {
		this.debugMessage = msg;
		this.invalidate();
	}

	try_invalidated_draw() {

		let isDraw = false;

		for (let eachElement in this.elements) {
			let element = this.elements[eachElement];

			if (element.is_invalidating_draw) {
				if (element.isLoading()) {
					var loadingPromise = element.getFirstLoadingPromise();
					if (loadingPromise) {
						if (this.isBatchUpdating) {
							this.batchUpdatePromises.push(loadingPromise);
						} else {
							loadingPromise.then(() => {
								this.try_invalidated_draw();
							});
						}
					}
				} else {
					if (this.isBatchUpdating) {
						this.batchUpdatePromises.push(Promise.resolve());
					} else {
						isDraw = true;
						element.validate();
					}
				}
			}
		}

		if (isDraw) {
			// console.log("canvas draw");
			this.drawFrame();
		} else {
			// console.log("try canvas draw");
		}
	}

	isCanvasEvent(e) {
		return e.target.nodeName == "CANVAS" || e.target.nodeName == "VIDEO";
	}
	mousedown(e) {

		if (!this.isCanvasEvent(e) || this.isInputDisabled) {
			return;
		}

		this.onActivity();

		let relative_e = new MouseEvent(e);
		for (let each in this.components) {
			this.components[each].mousedown(this, relative_e);
		}

		// this.try_invalidated_draw();
	}

	keydown(e) {
		if (this.isInputDisabled) {
			return;
		}

		this.onActivity();

		let ievent = new InteractiveEvent(this, e);

		this.state.author.diagnostics_overlay.set_key_code(ievent.e.keyCode, true);
		//this.set_new_debug_message("keyCode=" + e.keyCode);

		for (let each in this.components) {
			this.components[each].keydown(this, ievent);
			if (ievent.isStopPropagation) {
				break;
			}
		}

		this.try_invalidated_draw();
	}

	keyup(e) {
		if (this.isInputDisabled) {
			return;
		}

		this.onActivity();

		let ievent = new InteractiveEvent(this, e);

		this.state.author.diagnostics_overlay.set_key_code(ievent.e.keyCode, false);

		for (let each in this.components) {
			this.components[each].keyup?.(this, ievent);
			if (ievent.isStopPropagation) {
				break;
			}
		}

		this.try_invalidated_draw();
	}

	mouseup(e) {
		// hack: compare when the input was enabled within a millisecond to now to prevent jquery ui dialog resize from registering a mouseup interaction.
		if (!this.isCanvasEvent(e) || this.isInputDisabled || this.isInputEnabledMilliseconds + 3 >= Date.now()) {
			return;
		}

		this.onActivity();
		// console.log("mup " + this.isInputEnabledMilliseconds + " " + Date.now());
		let relative_e = new MouseEvent(e);
		for (let each in this.components) {
			this.components[each].mouseup(this, relative_e);
		}
		this.try_invalidated_draw();
	}

	mousemove(e) {
		if (!this.isCanvasEvent(e) || this.isInputDisabled) {
			return;
		}
		this.onActivity();
		let relative_e = new MouseEvent(e);
		for (let i = 0; i < this.components.length; i++) {
			this.components[i].mousemove(this, relative_e);
		}
		this.try_invalidated_draw();
	}

	get_width() {
		return this.canvas.clientWidth;
	}
	get_height() {
		return this.canvas.clientHeight;
	}
	resize() {
		console.log("resize");
		this.updateCanvasSize();
		this.drawFrame();
		this.onActivity();
		dialogOptions.width = this.canvas.width;
		dialogOptions.height = this.canvas.height;

		//$(`#${id}`).dialog(dialogOptions);
		//var found_dialog = document.getElementById(`${id}`);
		//found_dialog?.dialog(dialogOptions);

		// if (this.elements.length > 0) {
		// 	dialogOptions.width = this.elements[1].width;
		// 	dialogOptions.height = this.elements[1].height;
		// 	$(`#${id}`).dialog(dialogOptions);
		// }
	}

	start() {
		for (let each in this.components) {
			this.components[each].start();
		}
		this.updateCanvasSize();

		//this.try_invalidated_draw();
	}

	draw_point(shape, radius = 3, drawScope = DrawScope.Normal) {
		if (this.drawScope < drawScope) {
			return;
		}
		this.ctx.beginPath();
		this.ctx.fillStyle = "white";
		this.ctx.arc(shape.x, shape.y, radius, 0, 2 * Math.PI, true);
		this.ctx.fill();
	}
	draw_rect(shape, drawScope = DrawScope.Normal, lineWidth = 2) {
		if (this.drawScope < drawScope) {
			return;
		}
		this.ctx.beginPath();
		this.ctx.lineWidth = `${lineWidth}`;
		this.ctx.strokeStyle = "white";
		this.ctx.rect(shape.p.x, shape.p.y, shape.w, shape.h);
		this.ctx.stroke();
	}
	/**
	 * Draws a rectangle on a canvas with a semi-transparent background and a border.
	 * 
	 * @param {object} shape An object defining the rectangle's properties.
	 * @param {number} shape.p.x The x-coordinate of the rectangle's top-left corner.
	 * @param {number} shape.p.y The y-coordinate of the rectangle's top-left corner.
	 * @param {number} shape.w The width of the rectangle.
	 * @param {number} shape.h The height of the rectangle.
	 * @param {array} color The RGB color of the rectangle as an array [red, green, blue] (each 0-255).
	 * @param {number} backgroundOpacity The opacity of the rectangle's background (0-1).
	 * @param {number} borderWidth The width of the rectangle's border.
	 * @param {number} borderOpacity The opacity of the rectangle's border (0-1).
	 */

	draw_rect_with_border(shape, color = [0, 0, 0], backgroundOpacity = 0.75, borderWidth = 2, borderOpacity = 0.1) {

		const x = shape.p.x;
		const y = shape.p.y;
		const width = shape.w;
		const height = shape.h;

		this.ctx.fillStyle = `rgba(${color.join(', ')}, ${backgroundOpacity})`;
		this.ctx.fillRect(x, y, width, height);

		this.ctx.lineWidth = borderWidth;
		this.ctx.strokeStyle = `rgba(${color.join(', ')}, ${borderOpacity})`;
		this.ctx.strokeRect(x, y, width, height);
	}

	draw_text(string, position, size = 15, drawScope = DrawScope.Normal) {
		if (this.drawScope < drawScope) {
			return;
		}
		this.ctx.font = size + "px Georgia";
		this.ctx.fillStyle = "white";
		this.ctx.fillText(string, position.x, position.y);
		var textWidth = Math.floor(this.ctx.measureText(string).width);
		return textWidth;
	}

	draw_text_with_newlines(string, position, size = 15, drawScope = DrawScope.Normal) {
		if (this.drawScope < drawScope) {
			return;
		}
		this.ctx.font = size + "px Georgia";
		this.ctx.fillStyle = "white";

		var lines = string.split('\n');
		var lineHeight = size * 1.2;

		for (let i = 0; i < lines.length; i++) {
			this.ctx.fillText(lines[i], position.x, position.y + (i * lineHeight));
		}

		//var textWidth = Math.floor(this.ctx.measureText(string).width);
		//return textWidth;
	}

	move_point_up(point, amount) {
		let result = point.copy();
		result.y -= amount;
		return result;
	}
	invalidate() {
		//this.screenElement.invalidate();

		for (let eachElement in this.elements) {
			let element = this.elements[eachElement];
			element.invalidate();
		}
	}
	invaidate() {
		this.invalidate();
	}

	// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop

	collectFilesFromDragdropEvent(ev) {
		var files = [];

		if (ev.dataTransfer.items) {
			[...ev.dataTransfer.items].forEach((item, i) => {
				if (item.kind === "file") {
					const file = item.getAsFile();
					files.push({ file: file, dataTransfer: item });
				}
			});
		} else {
			[...ev.dataTransfer.files].forEach((file, i) => {
				files.push({ file: file });
			});
		}
		return files;
	}

	drop_file(ev) {
		ev.preventDefault();

		var files = this.collectFilesFromDragdropEvent(ev);

		this.file_dropped(ev, files);
		this.try_invalidated_draw();
	}

	drag_file(ev) {
		ev.preventDefault();

		var files = this.collectFilesFromDragdropEvent(ev);

		this.file_dragged(ev, files);
		this.try_invalidated_draw();
	}

	file_dropped(e, files) {
		for (let i in this.components) {
			var each = this.components[i];
			if (each.file_dropped) {
				each.file_dropped(e, files);
			}
		}
	}

	file_dragged(e, files) {
		for (let i in this.components) {
			var each = this.components[i];
			if (each.drag_file) {
				each.drag_file(e, files);
			}
		}
	}

	activate(value, value_context) {
		if (value == "interactive.input" && value_context?.keydown) {
			let e = {};
			e.key = value_context.keydown;
			this.keydown(e);
			return;
		}

		let event = new InteractiveEvent();
		event.activate_value = value;
		event.activate_value_context = value_context;

		for (var each = this.components.length - 1; each >= 0; each--) {
			let c = this.components[each];
			if (!c.activate) {
				continue;
			}
			c.activate(event);
			if (event.isStopPropagation) {
				break;
			}
		}
	}

	startBatchUpdate() {
		this.isBatchUpdating = true;
		this.batchUpdatePromises = [];
	}
	endBatchUpdate() {

		this.try_invalidated_draw();

		this.isBatchUpdating = false;

		if (this.batchUpdatePromises.length == 0) {
			return;
		}

		return Promise.all(this.batchUpdatePromises).then(() => {
			this.try_invalidated_draw();
		});
	}

	initializeVideoBuffers() {
		this.video_buffers.icanvas=this;
		this.video_buffers.initializeVideoBuffers();
	}
	get_error_callback(each_index, each_item) {
		return this.video_buffers.get_error_callback(each_index, each_item);
	}
	get_ended_callback(each_index, each_item) {
		return this.video_buffers.get_ended_callback(each_index, each_item);
	}
	get_canplay_callback(each_index, each_item) {
		return this.video_buffers.get_canplay_callback(each_index, each_item);
	}
	get_loadeddata_callback(each_index, each_item) {
		return this.video_buffers.get_loadeddata_callback(each_index, each_item);
	}
	get_playing_callback(each_index, each_item) {
		return this.video_buffers.get_playing_callback(each_index, each_item);
	}
	on_buffer_error(index, buffer, registered) {
		this.video_buffers.on_buffer_error(index, buffer, registered);
	}
	unregister_video_buffer_index(subject, index) {
		this.video_buffers.unregister_video_buffer_index(subject, index);
	}
	is_registered_video_buffer_index_as(subject, index) {
		return this.video_buffers.is_registered_video_buffer_index_as(subject, index);
	}
	register_next_video_buffer(subject) {
		return this.video_buffers.register_next_video_buffer(subject);
	}
	clear_buffer_by_index(index) {
		this.video_buffers.clear_buffer_by_index(index);
	}
	swap_buffer_to_index(index) {
		this.video_buffers.swap_buffer_to_index(index);
	}
	get_next_video_buffer_index(index) {
		return this.video_buffers.get_next_video_buffer_index(index);
	}
	get_video_buffer(index) {
		return this.video_buffers.get_video_buffer(index);
	}
	onCanvasResizedForVideoBuffers() {
		this.video_buffers.onCanvasResizedForVideoBuffers();
	}
}