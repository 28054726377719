
import { WebApplication } from '../../../webApplication';
import { GettingReadyCallback } from '../../../sceneGraph/GettingReadyCallbackCollector';
import { RectangleGeometry } from '../../../geometry/RectangleGeometry';

export class ResourceCanvasElement {
    vis;
    visual_resource;
    canvasElement;
    isHidden;
    onDrawing;
    // onError;
    isReady = false;
    isError;
    drawOrder;
    constructor(vis, visual_resource = undefined) {
        this.vis = vis;
        this.visual_resource = visual_resource;

        if (vis.scene.application.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
            //console.log("new:ResourceCanvasElement")
        }
    }

    get geometry() {
        if (this.vis.obj.visual_geometry) {
            return this.vis.obj.visual_geometry;
        }
        if (this.vis.obj.geometry) {
            return this.vis.obj.geometry;
        }
        if (this.visual_resource) {
            var size = this.visual_resource.pixel_size();
            var result = new RectangleGeometry();
            result.initialize(0, 0, size[0], size[1]);
            return result;
        }
        var result = new RectangleGeometry();
        result.initialize(0, 0, 0, 0);
        return result;
    }

    get url_file_info() {
        return this.visual_resource?.url_file_info;
    }
    get isLoadingSuccess() {

        if (this.visual_resource == undefined) {
            return false;
        }
        if (this.visual_resource.isLoaded != true) {
            return false;
        }
        this.visual_resource.checkForErrors();
        if (this.visual_resource.isError == true) {
            return false;
        }
        return true;
    }

    toSourceURLNameExt() {
        return this.visual_resource.resource_request?.toURLNameAndExtension();
    }
    toSourceURLName() {
        return this.visual_resource.toSourceURLName?.();
    }
    toSourceURL() {
        return this.canvasElement.toSourceURL?.();
    }
    get url() {
        return this.toSourceURL();
    }

    start_loading(gettingReadyCallbackCollector) {

        if (this.visual_resource == undefined) {
            this.isReady = true;
            return;
        }

        var promise = new Promise((resolve, reject) => {
            this.visual_resource.onVisualLoaded = (s) => {

                this.isReady = true;
                resolve("Operation successful!");
            };
            this.visual_resource.start_loading(this.vis.server_file_cache, this);
        });
        var callback = new GettingReadyCallback();
        callback.addPromise(promise);
        gettingReadyCallbackCollector.add(callback);

    }

    canvasElement_on_drawing(params) {
        this.onDrawing?.(params);
    }

    start() {

        if (!this.canvasElement) {
            this.canvasElement = this.visual_resource.createCanvasElement(this);
            this.canvasElement.onDrawing = this.canvasElement_on_drawing.bind(this);
            this.canvasElement.onError = (elm, err) => this.onElementError(elm, err);

        }

        this.canvasElement.setHidden(this.isHidden);

        if (this.drawOrder) {
            this.canvasElement.draw_order = this.drawOrder;
        } else {

            this.canvasElement.draw_order = this.vis.obj.scene.draw_order
        }

        this.vis.resource_displayed(this.visual_resource, true);
        this.visual_resource.start(this);

        this.vis.icanvas.addElement(this.canvasElement);
    }

    onElementError(elm, err) {

        var pause_error = "The play() request was interrupted by a call to pause()"
        if (err.includes(pause_error)) {
            return;
        }

        this.visual_resource.notifyError();

        this.isError = true;
        this.vis.onResourceCanvasElementError(this);
    }

    stop(next_resource_canvas_element) {

        if (this.visual_resource.isLoading()) {
            this.visual_resource.cancelLoading();
            this.isCanceled = true;
        } else {
            this.visual_resource.stop();
        }

        this.removeCanvasElement();
    }
    collectCanvasElements(result) {
        if (this.canvasElement) {
            result.push(this.canvasElement);
        }
    }
    setHidden(value) {
        this.canvasElement?.setHidden(value);
        this.isHidden = value;
    }
    setDrawOrder(value) {
        this.drawOrder = value;
        this.canvasElement?.setDrawOrder(value);    

    }

    removeCanvasElement() {
        if (this.canvasElement) {
            this.vis.icanvas.removeElement(this.canvasElement);
        }
    }
    isLoading() {
        return this.visual_resource?.isLoading();
    }

    // toRect(scaleToCanvas = true) {
    //     return this.visual_resource?.toRect(scaleToCanvas ? this.vis.icanvas : undefined);
    // }
}
