import { WebApplication } from '../webApplication';
import * as htmlHelper from "../htmlHelper.js";
import { VideoBufferStatus } from './interactive_canvas.js';

export class InteractiveCanvasVideoBuffers {

    icanvas;
	video_buffers;//= [undefined];//, undefined
	video_buffer_registrations; //= [undefined];//, undefined
	video_buffer_status;//=[undefined];
	visible_video_buffer_index;

	initializeVideoBuffers() {

		if (this.icanvas.application.getSetting(WebApplication.IsDBVideoEnabledSettingName)) {

			this.video_buffers = [undefined, undefined];
			this.video_buffer_registrations = [undefined, undefined];
			this.video_buffer_status = [undefined, undefined];
			this.video_buffers[0] = document.getElementById('video-a');
			this.video_buffers[1] = document.getElementById('video-b');

		} else if (this.icanvas.application.getSetting(WebApplication.IsSBVideoEnabledSettingName)) {

			this.video_buffers = [undefined];//, undefined
			this.video_buffer_registrations = [undefined];//, undefined
			this.video_buffer_status = [undefined];
			this.video_buffers[0] = document.getElementById('video-');
			htmlHelper.showElement(this.video_buffers[0]);
		}

		for (let index = 0; index < this.video_buffers?.length || 0; index++) {
			var each_item = this.video_buffers[index];

			each_item.autoplay = false;
			each_item.setAttribute('playsinline', 'playsinline');
			each_item.controls = false;

			var onError = this.get_error_callback(index, this.video_buffers[index]);
			each_item.addEventListener("error", onError);
			each_item.addEventListener("ended", this.get_ended_callback(index, this.video_buffers[index]));
			each_item.addEventListener("canplay", this.get_canplay_callback(index, this.video_buffers[index]));
			each_item.addEventListener("loadeddata", this.get_loadeddata_callback(index, this.video_buffers[index]));
			each_item.addEventListener("playing", this.get_playing_callback(index, this.video_buffers[index]));

		}
	}

	get_error_callback(each_index, each_item) {
		var self = this;
		var result = (event) => {
			const each_item_registration = self.video_buffer_registrations[each_index];
			console.error(`error:video:buffer ${each_item.id}`);
			self.on_buffer_error(each_index, each_item, each_item_registration);
		};
		return result;
	}

	get_ended_callback(each_index, each_item) {
		var self = this;
		var result = (event) => {
			console.info(`video:ended ${each_item.id}`);
			const each_item_registration = self.video_buffer_registrations[each_index];

			each_item_registration?.on_buffer_ended(each_index, each_item);
		};
		return result;
	}

	get_canplay_callback(each_index, each_item) {
		var self = this;
		var result = (event) => {
			const each_status = self.video_buffer_status[each_index];
			if (each_status == undefined) {
				return;
			}
			each_status.canplay_count += 1;
			if (each_status.canplay_count > 1) {
				return;
			}

			const each_item_registration = self.video_buffer_registrations[each_index];
			console.info(`video:canplay ${each_item.id} ${each_item_registration?.toSourceURLName()}`);


			//next.currentTime = 0;
			if (each_item.paused) {
				const each_item_registration = self.video_buffer_registrations[each_index];
				console.info(`video:play ${each_item.id} ${each_item_registration?.toSourceURLName()}`);
				each_item.play();
				each_item_registration?.on_buffer_play(each_index, each_item);
			}

		};
		return result;
	}

	get_loadeddata_callback(each_index, each_item) {
		var self = this;
		var result = (event) => {
			const each_item_registration = self.video_buffer_registrations[each_index];
			console.info(`video:loadeddata ${each_item.id} ${each_item_registration?.toSourceURLName()}`);

		};
		return result;
	}

	get_playing_callback(each_index, each_item) {
		var self = this;
		var result = (event) => {
			const each_status = self.video_buffer_status[each_index];
			if (each_status == undefined) {
				return;
			}
			each_status.playing_count += 1;
			if (each_status.playing_count > 1) {
				return;
			}
			const each_item_registration = self.video_buffer_registrations[each_index];
			console.info(`video:playing ${each_item.id} ${each_item_registration?.toSourceURLName()}`);
			each_item_registration?.on_buffer_playing(each_index, each_item);

		};
		return result;
	}

	on_buffer_error(index, buffer, registered) {
		if (registered) {
			registered.on_buffer_error(index, buffer);
			//this.clear_buffer_by_index(index);
		}
		else {
			this.clear_buffer_by_index(index);
		}
	}
	unregister_video_buffer_index(subject, index) {
		var registered = this.video_buffer_registrations[index];

		if (registered !== subject) {
			console.warn(`unregistered video buffer with different registration: ${subject.toSourceURLName()}`)
			return false;
		}

		this.video_buffer_status[index] = undefined;
		this.video_buffer_registrations[index] = undefined;
	}

	is_registered_video_buffer_index_as(subject, index) {
		var registered = this.video_buffer_registrations[index];

		if (registered !== subject) {
			return false;
		}
		return true;
	}
	register_next_video_buffer(subject) {
		var r;

		if (this.visible_video_buffer_index == undefined) {
			r = 0;
		} else {
			r = this.get_next_video_buffer_index(this.visible_video_buffer_index);

		}

		if (this.video_buffer_registrations[r] != undefined) {
			console.error(`registered video buffer with different registration: ${subject.toSourceURLName()}`)

			return undefined;
		}

		this.video_buffer_status[r] = new VideoBufferStatus();
		this.video_buffer_registrations[r] = subject;

		return r;
	}


	clear_buffer_by_index(index) {
		var previous = this.video_buffers[index];

		previous.pause();
		previous.currentTime = 0;

		previous.removeAttribute('src');

		previous.removeAttribute('poster');

		//	if (this.application.getSetting(WebApplication.IsDBVideoEnabledSettingName)) {
		htmlHelper.hideElement(previous);
		//	}
	}

	swap_buffer_to_index(index) {

		var next = this.video_buffers[index];

		var next_id = next.id;
		var name = this.video_buffer_registrations[index]?.toSourceURLName() ?? "";
		console.info(`video:play id=${next_id}, name=${name},  video_src=${next.src}`);

		// next.currentTime = 0;
		// next.play();

		if (this.icanvas.application.getSetting(WebApplication.IsDBVideoEnabledSettingName)) {
			if (this.visible_video_buffer_index != undefined) {
				this.clear_buffer_by_index(this.visible_video_buffer_index);
			}
		}

		htmlHelper.showElement(next);

		this.visible_video_buffer_index = index;
	}
	get_next_video_buffer_index(index) {
		var next = index + 1;
		next = next % this.video_buffers.length;
		return next;
	}
	get_video_buffer(index) {
		return this.video_buffers[index];
	}
	onCanvasResizedForVideoBuffers() {
		if (this.visible_video_buffer_index != undefined) {
			var registered = this.video_buffer_registrations[this.visible_video_buffer_index];
			if (registered != undefined) {
				registered.onCanvasResized?.();
			}
		}
	}
}
