import { Treeview } from "../view/treeview";
import { v4 as uuidv4 } from "uuid";
import * as htmlHelper from "../htmlHelper";
// import $ from "jquery";
// import "jquery-ui";
// import "jquery-ui-css";
import { BaseModules, ModulesCommon } from "./ModulesCommon";

export class InternalModules extends BaseModules {
  external_modules;
  authoringInterface;
  json;
  constructor(external_modules, json = {}) {
    super();
    this.external_modules = external_modules;
    this.json = json;
  }
  initialize() {}

  saveState() {
    // this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
  }
  shutdown() {
    this.saveState();
  }
}
