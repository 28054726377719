export class GettingReadyCallback {
  name;
  promises = [];
  isCanceled = false;
  error = null; 

  constructor(name) {
    this.name = name;
  }

  addPromise(promise) {
    this.promises.push(promise);
  }

  cancel() {
    this.isCanceled = true;
  }
}

export class GettingReadyCallbackCollector {
  calllbacks = [];
  errors;
  is_auto_invalidate=false;
  isCanceled;

  cancel(){
    this.isCanceled=true;
  }

  add(callback) {
    this.calllbacks.push(callback);
  }

  newWaitPromiseForAllCallbacks() {
    const allPromises = this.calllbacks.flatMap(
      (callback) => callback.promises
    );

    return Promise.allSettled(allPromises).then((results) => {
      this.calllbacks.forEach((callback) => {
        const callbackResults = results.filter(
          (result) => callback.promises.includes(result.value)
        );

        // Set error field for individual callbacks
        const callbackErrors = callbackResults.filter(
          (result) => result.status === "rejected"
        );
        callback.error = callbackErrors.length > 0 ? callbackErrors[0].reason : null;

        callback.isReady =
          callbackResults.length > 0 &&
          callbackResults.every((result) => result.status === "fulfilled");
      });

      this.errors = results
        .filter((result) => result.status === "rejected")
        .map((result) => result.reason);
    });
  }
}
