import { VisualElement } from './visualElement';
import { VisualElements } from './VisualElements';

export class AudioVisualPlaybackQueue {
    looping_stems;

    visual_elements;

    set_looping_stems(stems) {
        this.looping_stems = stems;
    }
    start_loading(obj, gettingReadyCallbackCollector) {

        this.visual_elements = [];

        if (this.looping_stems) {
            for (const each of this.looping_stems) {
                if (VisualElements.VideoVisualResourceType?.canCreateResourceFromJsonObject(each)) {

                    var visual_element = new VisualElement(obj, each, obj.getResourcePath());

                    visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);

                    this.visual_elements.push(visual_element);
                }
            }
        }

    }

    start(obj) {
        for (const each of this.visual_elements) {
            each.start();
        }
    }

    stop(obj, next_resource_canvas_element) {
        for (const each of this.visual_elements) {
            each.stop(next_resource_canvas_element);
        }
    }
}
