//@ts-check

import { AudioListenerScope } from "./AudioListenerScope";
import { Stem } from "./stem";

/**
 * @callback stringFunction
 * @returns {string}
*/

/**
 * 
 */
export class AudioAmbiance {
  /**
   *  @type {import('./audioAmbianceSet').AudioAmbianceSet}
   */
  ambianceSet;
  /**
   *  @type {import('./AmbianceContext').AmbianceContext}
   */
  audioContext;
  /**
   *  @type {object}
   */
  json;
  /**
* 
* @type {Array.<import('./stem').Stem>}
*/
  stems = [];
  /**
* 
* @type {Array.<import('./stem').Stem>}
*/
  sound_effects_played = []
  /**
* 
* @type {stringFunction}
*/
  parentGetResourcePath;
  /**
   * @returns {string|undefined}
   */
  get regionName() {
    return this.audioContext.regionName;
  }
  /**
   * @returns {string|undefined}
   */
  get areaName() {
    return this.audioContext.areaName;
  }
/**
 * 
 * @param {import('./AudioAmbianceGroup').AudioAmbianceGroup} audio_ambiance_group 
 */
  onListenLocationExit(audio_ambiance_group) {
    for (const each of this.sound_effects_played) {
      if (each.json.isListenerAreaLocal == true) {
        audio_ambiance_group.audio.connectedStems.stopStem(each);
      }
    }
    this.sound_effects_played = [];
  }
/**
 * 
 * @param {Array.<import('./stem').Stem>} stems 
 */
  onPlaySoundEffects(stems) {
    this.sound_effects_played.push(...stems);
  }
/**
 * 
 * @param {import('./AudioAmbianceGroup').AudioAmbianceGroup} group 
 * @param {import('./AmbianceContext').AmbianceContext} context 
 */
  collectAmbianceGroup(group, context) { }
/**
 * 
 * @param {import('./AudioAmbianceGroup').AudioAmbianceGroup} group 
 * @param {import('./AmbianceContext').AmbianceContext} context 
 */
  collectConnectedAmbianceGroup(group, context) { }

  /**
   * 
   * @param {import('./AmbianceContext').AmbianceContext} audioContext 
   * @param {object} json 
   * @param {stringFunction} parentGetResourcePath 
   */
  constructor(audioContext, json = undefined, parentGetResourcePath ) {
    this.audioContext = audioContext;

    this.parentGetResourcePath = parentGetResourcePath;

    this.json = json || {};

    this.json.stems?.forEach((element) => {
      let stem = this.newStem(element);
      this.stems.push(stem);
    });
  }
/**
 * 
 * @param {object} element 
 * @returns {Stem}
 */
  newStem(element) {
    let stem = new Stem(element, this.getResourcePath.bind(this));

    return stem;
  }
/**
 * 
 * @returns {string}
 */
  getResourcePath() {
    return this.json.resourcePath || this.parentGetResourcePath();
  }

  /**
   * 
   * @returns {Array.<Stem>}
   */
  get_soundscape() {
    var result = [];

    this.stems.forEach((each) => {
      if (!each.isEnabled) {
        return;
      }
      result.push(each);
    });

    this.ambianceSet.collection.forEach((each_ambiance) => {
      if (each_ambiance === this) {
        return;
      }

      each_ambiance.stems.forEach((each) => {
        if (!each.isEnabled) {
          return;
        }
        if (each.hasRegionGain()) {
          if (result.some((each_result) => each.isEquivilentToStem(each_result))) {
            return;
          }
          result.push(each.toRegionStem());
        }
      });
    });

    return result;
  }
}
