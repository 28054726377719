import { SceneObjects } from "./scene_objects.js";
import { SceneObject } from './SceneObject.js';
import { Interactives } from "./interactives.js";
import { AudioAmbiance } from "../audio_visual/audio/audioAmbiance.js";
import { Interactive } from "./Interactive.js";
import { VisualElement } from "../audio_visual/visual/visualElement.js";
import { ScenePath } from "./ScenePath.js";
import { InteractiveLayer, InteractiveLayerJson } from "./InteractiveLayer.js";
import { InteractiveLayers } from "./InteractiveLayers.js";
import { AudioListenerScope } from "../audio_visual/audio/AudioListenerScope.js";
import { AmbianceContext } from "../audio_visual/audio/AmbianceContext.js";
import { Actions } from './actions/actions.js';
import { RectangleGeometry } from '../geometry/RectangleGeometry.js';
import { GettingReadyCallbackCollector } from './GettingReadyCallbackCollector.js';
let c2 = require("c2.js");
let geometry_js = require("../geometry.js");


export class Scene {
  scene_graph;
  scene_graph_node;
  name;
  json;
  visual_element;
  audioAmbiance;
  objects;
  interactiveLayers;
  isVisualError = false;
  geometry;

  constructor(scene_graph, name, json) {
    this.scene_graph = scene_graph;
    this.name = name;
    this.json = json;

    this.initializeFromJson();

    this.audioAmbiance = new AudioAmbiance(
      this.toAudioContext(),
      this.json.audioAmbiance,
      this.getResourcePath.bind(this)
    );
  }

  isFadedOut(){

    if (this.sceneInteractiveLayer.isFadedOut) {
      return true;
    }
    return false;
  }
  onPlaySoundEffects(stems) {
    this.audioAmbiance.onPlaySoundEffects(stems);
  }

  isVisualContentReady() {
    if (!this.visual_element?.isReadyOrEmpty()) {
      return false;
    }
    if (this.objects && !this.objects.isVisualContentReady()) {
      return false;
    }

    return this.interactiveLayers.isVisualContentReady();
  }

  get sceneInteractiveLayer() {
    return this.interactiveLayers.default_layer;
  }
  get sceneInteractiveLayerInteractives() {
    return this.sceneInteractiveLayer.interactives;
  }
  get visualElements() {
    return this.scene_graph_node.visualElements;
  }
  get resources() {
    return this.scene_graph.resources;
  }
  get icanvas() {
    return this.sceneGraph.icanvas;
  }
  get simulation() {
    return this.scene_graph_node.simulation;
  }
  get sceneGraph() {
    return this.scene_graph;
  }
  get sceneGraphNode() {
    return this.scene_graph_node;
  }
  get sceneLayerOrder() {
    return this.json.sceneLayerOrder;
  }
  get scene() {
    return this;
  }
  get localScene() {
    return this;
  }
  get globalScene() {
    return this;
  }
  get application() {
    return this.scene_graph.application;
  }
  get server() {
    return this.scene_graph.simulation.server;
  }
  get server_file_cache() {
    return this.server.server_file_cache;
  }
  get visualElements() {
    return this.scene_graph_node?.visualElements;
  }
  get draw_order() {
    return this.sceneLayerOrder;
  }
  get geometry_isRelativeTo() {
    return undefined;
  }

  initializeFromJson() {

    if (this.json.sceneLayerOrder == undefined) {
      this.json.sceneLayerOrder = 1;
    }

    this.interactiveLayers = new InteractiveLayers(this);

    this.objects?.initializeFromJson();

    this.interactiveLayers.setDefaultLayer(this.json);
    this.interactiveLayers.initializeFromJson();

    if (this.json.inactivityFadeoutSeconds != undefined) {
      this.interactiveLayers.default_layer.json.inactivityFadeoutSeconds = this.json.inactivityFadeoutSeconds;
    }


  }

  activate_event(ievent) {
    this.interactiveLayers.activate_event(ievent);
  }

  activate(value, value_context) {
    this.interactiveLayers.activate(value, value_context);
  }

  getResourcePathFunction() {
    return this.getResourcePath.bind(this);
  }

  toAudioContext() {
    var audioContext = new AmbianceContext(AudioListenerScope.fromScenePath(this.toScenePath(), this, this.sceneGraph));
    return audioContext;
  }

  toScenePath() {
    return new ScenePath(this.scene_graph.name, this.name);
  }

  push(item) {
    if (item instanceof InteractiveLayerJson) {
      this.interactiveLayers.pushLayer(item.json);
    }
  }

  removeTemporary() {
    this.sceneInteractiveLayer.removeTemporary();
  }
  canNavigateBack() {
    return this.simulation.player.canNavigateBack();
  }
  navigateBack() {
    this.simulation.player.navigateBack();
  }
  navigateHome() {
    this.simulation.player.navigateHome();
  }
  navigate(scene_name, scene_graph_name = undefined) {
    if (scene_graph_name == undefined) {
      scene_graph_name = this.sceneGraph.name;
    }
    this.simulation.player.startChangeLocation(new ScenePath(scene_graph_name, scene_name));
  }

  graphStarted(previousPath = undefined) { }

  startVisualContentGetReady(gettingReadyCallbackCollector) {

    if (!this.visual_element) {
      this.visual_element = new VisualElement(this, this.json, this.getResourcePath());
    }

    this.geometry = RectangleGeometry.createGeometryFromJson_Relative(this.json, this, undefined,undefined,
      geometry_js.Geometry.CoordinatesType.ABSOLUTE,
      geometry_js.Geometry.CoordinatesType.ABSOLUTE);
    this.geometry.letterboxInto = this.icanvas;

    //var test_rect = this.geometry.get_absolute_shape();

    this.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);

    this.objects?.startVisualContentGetReady(gettingReadyCallbackCollector);
    this.interactiveLayers.startVisualContentGetReady(gettingReadyCallbackCollector);
  }

  start(previousScene = undefined) {

    if (!this.visual_element) {

      console.info("loading scene: " + this.name);
      this.callback_collector = new GettingReadyCallbackCollector();
      this.startVisualContentGetReady(this.callback_collector);
      this.callback_collector_promise = this.callback_collector.newWaitPromiseForAllCallbacks().then((result) => {
        this.callback_collector_promise = undefined;
        this.callback_collector = undefined;
        this.start(previousScene);
        this.icanvas.try_invalidated_draw();
      });
      return;
    }

    console.info("start scene: " + this.name);

    this.visual_element.start();

    this.objects?.start();
    this.interactiveLayers.start();

    this.scene_graph_node.listener.addAudioAmbiance(this.audioAmbiance);

    if (this.json["scene.event.start"]) {

      var actions = new Actions();
      actions.parseJson(this.json["scene.event.start"], this.application);
      actions.runActions(this);
    }
  }


  onVisualError(resource) {
    this.isVisualError = true;
  }

  onVisualDisplayed(resource, success) {

    if (success && !this.isVisualError) {
      console.info("scene visual is displayed");

    }
  }

  stop(nextScene = undefined) {

    console.info("stop scene: " + this.name);
    this.objects?.stop();
    this.interactiveLayers.stop();
    var next_vis = nextScene?.visual_element;
    this.visual_element.stop(next_vis?.firstResourceCanvasElement());
    this.scene_graph_node.listener.removeAudioAmbiance(this.audioAmbiance, nextScene?.audioAmbiance);
    this.removeTemporary();
  }

  graphStopped(nextPath = undefined) { }

  getResourcePath() {
    return this.json.resourcePath || this.scene_graph.json.resourcePath;
  }


  // deriveRectForScene(scaleToCanvas = true) {

  //   var defaultW = 3840;
  //   var defaultH = 2160;

  //   if (scaleToCanvas) {
  //     var hRatio = this.simulation.icanvas.get_width() / defaultW;
  //     var vRatio = this.simulation.icanvas.get_height() / defaultH;
  //     var ratio = Math.min(hRatio, vRatio);
  //     return new c2.Rect(0, 0, defaultW * ratio, defaultH * ratio);
  //   }

  //   return new c2.Rect(0, 0, defaultW, defaultH);
  // }

  // toRect(scaleToCanvas = true) {
  //   if (scaleToCanvas && this.scene_graph_node == undefined) {
  //     return new c2.Rect(0, 0, 0, 0)
  //   }

  //   if (this.visual_element.isReady()) {
  //     var visual_rect = this.visual_element.toRect(scaleToCanvas);
  //     if (visual_rect) {
  //       return visual_rect;
  //     }
  //   }

  //   return this.deriveRectForScene(scaleToCanvas);
  // }

  // getScreenSpaceAreaRect() {
  //   return this.toRect();
  // }
  getScreenSpaceAreaRect() {
    return this.geometry.get_absolute_rect_shape();
  }
  drawFrame(icanvas) {

    if (this.scene_graph_node == undefined) {
      return;
    }

    this.interactiveLayers.drawFrame(icanvas);
  }
  mousedown(ievent) {
    this.interactiveLayers.mousedown(ievent);
  }
  mouseup(ievent) {
    this.interactiveLayers.mouseup(ievent);
  }
  mousemove(ievent) {
    if (!this.isIEventOnGeometry(ievent)) {
      return;
    }

    this.interactiveLayers.mousemove(ievent);
  }
  keydown(ievent) {
    this.interactiveLayers.keydown(ievent);
  }
  keyup(ievent) {
    this.interactiveLayers.keyup(ievent);
  }
  onTouchTap(ievent) {
    this.interactiveLayers.onTouchTap(ievent);
  }
  onTouchPan(ievent) {
    this.interactiveLayers.onTouchPan(ievent);
  }
  onTouchSwipe(ievent) {
    this.interactiveLayers.onTouchSwipe(ievent);
  }
  onTouchDistance(ievent) {
    this.interactiveLayers.onTouchDistance(ievent);
  }
  onTouchRotate(ievent) {
    this.interactiveLayers.onTouchRotate(ievent);
  }
  onTouchGesture(ievent) {
    this.interactiveLayers.onTouchGesture(ievent);
  }

  file_dropped(ievent) {
    this.interactiveLayers.file_dropped(ievent);
  }
  drag_file(ievent) {
    this.interactiveLayers.drag_file(ievent);
  }
  static getNameFromFilename(file) {
    return file.name;
  }
  static newJsonFromFile(file) {
    return {};
  }

  findInteractiveByName(name, isTemporary) {
    return this.interactiveLayers.default_layer.findInteractiveByName(name, isTemporary);
  }

  convertEventWithPointToRelativePoint(e) {
    var asMouse = { x: e.offsetX, y: e.offsetY };

    var rect = this.toRect();

    var result = new c2.Point(asMouse.x / rect.w, asMouse.y / rect.h);

    return result;
  }
  isIEventOnGeometry(ievent) {
    var geometry = this.getScreenSpaceAreaRect();
    let mouse_point = geometry_js.mouseEventToPoint(ievent.e);
    return geometry.intersects(mouse_point);
  }
}
