import { AudioVisualPlaybackQueue } from './../AudioVisualPlaybackQueue';

export class AudioVisualScriptResourceCanvasElement {
    vis;
    visual_resource;

    onDrawing;
    isReady = false;
    isError;
    playback;

    active_state;
    active_script;

    get av_script() {
        return this.visual_resource;
    }

    constructor(vis, visual_resource) {
        this.vis = vis;
        this.visual_resource = visual_resource;
        this.playback = new AudioVisualPlaybackQueue();

        if (this.visual_resource.default_state) {
            this.active_state = this.visual_resource.state_set.getNewStateObjectByName(this.visual_resource.default_state);
        } else {
            this.active_state = this.visual_resource.state_set.getDefaultState();
        }

        if (this.active_state) {
            this.active_state.loadPlayback(this.playback);
        }
    }

    start_loading(gettingReadyCallbackCollector) {
        this.playback.start_loading(this.vis.obj, gettingReadyCallbackCollector);
        this.isReady = true;
    }

    start() {
        this.playback.start(this.vis.obj);
    }

    stop(next_resource_canvas_element) {
        this.playback.stop(this.vis.obj, next_resource_canvas_element);
    }
}
