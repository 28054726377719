
let geometry_js = require("../geometry.js");
import { DrawScope } from "../audio_visual/DrawScope.js";
import { VisualElement } from "../audio_visual/visual/visualElement.js";
import { RectangleGeometry } from '../geometry/RectangleGeometry.js';

export class InteractiveArea {
  static typename = "interactive.area";
  interactive;
  geometry;
  json;

  visual_element;

  get resources() {
    return this.interactive.resources;
  }
  get icanvas() {
    return this.interactive.icanvas;
  }

  get scene() {
    return this.interactive.scene;
  }
  get visualElements() {
    return this.scene.visualElements;
  }
  get application() {
    return this.scene.application;
  }
  get geometry_isRelativeTo() {
    return this.scene;
  }
  constructor(interactive, json) {
    this.interactive = interactive;
    this.json = json;

  }

  initializeFromJson() {


  }

  isVisualContentReady() {
    if (!this.visual_element?.isReadyOrEmpty()) {
      return false;
    }
    return true;
  }


  onVisualDrawing(params) {
    if (this.scene.isFadedOut()) {     
        params.isCancel = true;     
    }
  }

  drawFrame(icanvas) {

    this.visual_element.drawFrame(icanvas);
    //console.log("area draw");

    // if (this.scene.simulation.is_ux_faded_out()) { 
    //   return; 
    // }

    this.geometry.border_size = 2;
    var outline_scope = DrawScope.Debug;

    if (this.scene.simulation.selectionMode.isSceneInteractionMode()) {
      if (this.interactive.isSelected && !this.interactive.hasPointOfInterest()) {

        var ux_scene = this.scene.simulation.getUXScene();
        if (ux_scene) {
          var ux_scene_interactive_layer = ux_scene.sceneInteractiveLayer;

          if (!ux_scene_interactive_layer.isFadedOut) {

            outline_scope = DrawScope.Normal
            this.geometry.border_size = 5;
          }
        }

      }
    }

    this.geometry.draw(icanvas, outline_scope);
  }

  mousedown(ievent) { }

  isIEventOnInteractiveArea(ievent) {
    let mouse_point = geometry_js.mouseEventToPoint(ievent.e);
    return this.geometry.containsPoint(mouse_point);
  }
  getScreenSpaceAreaRect() {
    return this.geometry.get_absolute_rect_shape();
  }

  mouseup(ievent) {
    if (this.isIEventOnInteractiveArea(ievent)) {
      this.interactive.onMouseUp(ievent, this);
    }
  }
  onTouchTap(ievent) {
    if (this.isIEventOnInteractiveArea(ievent)) {
      this.interactive.onMouseUp(ievent, this);
    }
  }

  mousemove(ievent) { }

  isSelectable() {
    return this.geometry != undefined && this.geometry.scale != 0 && this.json.isSelectable != false;
  }

  startVisualContentGetReady(gettingReadyCallbackCollector) {

    if (!this.visual_element) {
      this.visual_element = new VisualElement(this, this.json, this.getResourcePath());
    }

    this.geometry = RectangleGeometry.createGeometryFromJson_Relative(this.json, this, this.scene, this.scene);

    var test_rect = this.geometry.get_absolute_shape();

    this.visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);
  }

  start() {

    this.visual_element.start();
    this.visual_element.onDrawing = (params) => this.onVisualDrawing(params);
  }

  stop() {
    this.visual_element.stop();
  }

  getResourcePath() {
    return this.json.resourcePath || this.interactive.getResourcePath();
  }

  collectCanvasElements(result) {
    this.visual_element.collectCanvasElements(result);
  }
  setAreaReletivePosition(point) {
    if (this.geometry) {
      this.geometry.setPosition(point);
      this.icanvas.invaidate();
    }
  }

  // toRect(scaleToCanvas = false) {

  //   return this.getScreenSpaceAreaRect();
  // }
}
