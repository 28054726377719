import { ImageCanvasElement } from "../canvasElements/imageCanvasElement";
import { ResourceCanvasElement } from '../resourceCanvasElements/ResourceCanvasElement';
import { WebApplication } from '../../../webApplication';
import { FileResourceRequest } from '../../../resources/FileResourceRequest';

import { FileResource } from '../../../resources/FileResource';

let c2 = require("c2.js");

export class ImageResource extends FileResource {
  static errrorImage;
  static webp_extension = ".webp";
  static png_extension = ".png";
  static svg_extension = ".svg";
  static webp_category = "visuals/images/webp/";
  static png_category = "visuals/images/png/";
  static svg_category = "visuals/images/svg/";
  static json_name = "image";

  checkForErrors() {
    return false;
  }
  createResourceCanvasElement(vis) {
    return new ResourceCanvasElement(vis, this);
  }
  notifyError() {
    if (!this.isError) {
      this.isError = true;
    }
  }
  static canCreateResourceFromJson(json, property) {
    return property == "image" && json[property] !== undefined;
  }
  static canCreateResourceFromJsonObject(json) {
    return json["image"] !== undefined;
  }
  static requestResource(name, path, path2, webapp) {
    let request = new FileResourceRequest(path, name, "", "", path2, true, false, false, webapp);

    var resource;

    var webp_enabled = webapp.getSetting(WebApplication.IsWebpEnabledSettingName);
    var webp_image_path = request.fullpathWithExtension(ImageResource.webp_extension, ImageResource.webp_category);
    var svg_image_path = request.fullpathWithExtension(ImageResource.svg_extension, ImageResource.svg_category);
    var webp_image_asset = webapp.server.server_file_cache.findFile(webp_image_path);
    var svg_image_asset = webapp.server.server_file_cache.findFile(svg_image_path);

    if (svg_image_asset) {
      request.setExtension(ImageResource.svg_extension);
      request.setCategoryPath(ImageResource.svg_category);
      resource = new SvgImageResource();
    }
    else if (webp_enabled && webp_image_asset) {
      request.setExtension(ImageResource.webp_extension);
      request.setCategoryPath(ImageResource.webp_category);

      if (webapp.getSetting(WebApplication.IsWebpWebAssemblyEnabledSettingName)) {
        resource = new WebPWebAssemblyImageResource();
      } else {
        resource = new WebPImageResource();
      }
    }
    else {
      request.setExtension(ImageResource.png_extension);
      request.setCategoryPath(ImageResource.png_category);
      resource = new PngImageResource();
    }

    if (resource) {

      resource.resource_request = request;
      resource.url = resource.resource_request.toUrlPath();
      resource.server_file_cache = webapp.server.server_file_cache;
      resource.url_file_info = resource.server_file_cache.server_asset_lookup[resource.url];
      resource.type = "image";
    }

    return resource;
  }

  static CopyImageJsonToJsonProperties(json) {
    var result = {
    };

    var key = "image";
    var key_dot = key + ".";

    for (let each in json) {
      if (each == key) {
        result["name"] = json[each];
      } else if (each.startsWith(key_dot)) {
        var new_key = each.slice(key_dot.length);
        result[new_key] = json[each];
      }
    }
    return result;
  }

  static CopyImageJson(json, replace_key_name = undefined, replaced_name_prefix = undefined) {

    var result = {
    };

    var replace_key_name_dot = replace_key_name + ".";
    var key = "image";
    var key_dot = key + ".";

    for (let each in json) {

      if (replace_key_name) {
        if (each == replace_key_name) {
          result[key] = replaced_name_prefix ? replaced_name_prefix + json[each] : json[each];

        } else if (each.startsWith(replace_key_name_dot)) {
          var new_key = key_dot + each.slice(replace_key_name_dot.length);
          result[new_key] = json[each];
        }
      } else {
        if (each == key || each.startsWith(key_dot)) {
          result[each] = json[each];
        }
      }
    }

    return result;
  }

  static createResourceFromJson(json, property, path, path2, webapp) {
    if (this.canCreateResourceFromJson(json, property)) {
      let result = this.requestResource(json.image, path, path2, webapp);
      result.centerRotateDegrees = json["image.rotate"];
      result.scale = json["image.scale"];
      result.align = json["image.align"];
      result.corner_radius = json["image.corner_radius"];
      result.json_properties = ImageResource.CopyImageJsonToJsonProperties(json);
      return result;
    }
  }
  resource_element;
  resource_request;
  loading_promise;
  isError;
  isLoaded;
  json_properties;

  centerRotateDegrees;
  scale;
  corner_radius;
  onVisualLoaded;


  constructor() {
    super();
  }

  toSourceURLName() {
    return this.resource_request.name;
  }
  start_loading(server_file_cache, resource_canvas_element) {

    if (this.resource_request.application.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
      console.log(`image:loading ${this.resource_request.toUrlPath()}`);
    }
    var self = this;

    this.resource_element = new Image();
    this.resource_request.application.memory_diagnostics.start_download("image", this.resource_request, this.resource_element);

    this.loading_promise = new Promise((resolve, reject) => {
      this.resource_element.addEventListener("load", () => {
        self.loading_promise = undefined;
        self.isLoaded = true;
        self.isError = false;
        self.onVisualLoaded?.(self);
        resolve(this.resource_element);
      });
      self.resource_element.addEventListener("error", function () {
        self.loading_promise = undefined;
        self.isLoaded = true;
        self.isError = true;
        self.onVisualLoaded?.(self);
        console.warn("missing resource: " + self.resource_request.filename);
        resolve(self.resource_element);
      });

      this.server_file_cache.loadFromCacheOrUrl(this.resource_element, this.url);

    });
  }

  initialize() {

  }
  start(resource_canvas_element) {

  }
  stop() {

  }
  isLoading() {
    return this.loading_promise != undefined;
  }

  getLoadingPromise() {
    return this.loading_promise;
  }
  pixel_size() {
    return [this.imageWidth(), this.imageHeight()];
  }
  imageWidth() {
    var result = 0;

    if (this.isError) {
      result = 1920;
    }
    else if (this.resource_element && this.resource_element.width != undefined) {
      result = this.resource_element.width;
    } else if (this.url_file_info && this.url_file_info.width != undefined) {
      result = this.url_file_info.width;
    }

    // var scaler = this.scale == undefined ? 1 : this.scale;
    // result *= scaler;

    return result;
  }

  imageHeight() {
    var result = 0;

    if (this.isError) {
      result = 1080;
    }
    else if (this.resource_element && this.resource_element.height != undefined) {
      result = this.resource_element.height;
    } else if (this.url_file_info && this.url_file_info.height != undefined) {
      result = this.url_file_info.height;
    }

    // var scaler = this.scale == undefined ? 1 : this.scale;
    // result *= scaler;

    return result;
  }


  createCanvasElement(resource_canvas_element) {

    return new ImageCanvasElement(this, this.resource_element, resource_canvas_element);
  }
}

export class ImageElementImageResource extends ImageResource {

  constructor() {
    super();
  }
}
export class PngImageResource extends ImageElementImageResource {

  constructor() {
    super();
  }
}
export class WebPImageResource extends ImageElementImageResource {

  constructor() {
    super();
  }
}

export class WebPWebAssemblyImageResource extends ImageResource {

  constructor() {
    super();
  }
}
export class SvgImageResource extends ImageResource {

  constructor() {
    super();
  }
}
