//@ts-check
import { WebApplication } from '../../../webApplication';
import { GettingReadyCallback, GettingReadyCallbackCollector } from '../../../sceneGraph/GettingReadyCallbackCollector';
import { RectangleGeometry } from '../../../geometry/RectangleGeometry';
import { OnDrawingParams } from '../OnDrawingParams';

/**
 *
 * @callback setDrawOrder_ResourceInterfaceFunction
 * @param {number} value
 */
/**
 *
 * @callback setHidden_ResourceInterfaceFunction
 * @param {boolean} value
 */
/**
 *
 * @callback toSourceURLNameExt_ResourceInterfaceFunction
 * @returns {string|undefined} 
 */
/**
 *
 * @callback isLoading_ResourceInterfaceFunction
 * @returns {boolean|undefined} 
 */
/**
 *
 * @callback start_loading_ResourceInterfaceFunction
 * @param {GettingReadyCallbackCollector} value
 */
/**
 *
 * @callback start_ResourceInterfaceFunction
 */
/**
 *
 * @callback stop_ResourceInterfaceFunction
 * @param {ResourceCanvasElementInterface|undefined} next
 */

/**
 * @typedef ResourceCanvasElementInterface
 * @property {object|undefined} [url_file_info]
 * @property {import('../resources/resourceInterface').ResourceInterface} visual_resource
 * @property {setDrawOrder_ResourceInterfaceFunction} setDrawOrder
 * @property {setHidden_ResourceInterfaceFunction} setHidden
 * @property {toSourceURLNameExt_ResourceInterfaceFunction} toSourceURLNameExt
 * @property {boolean} isReady
 * @property {boolean} isError
 * @property {start_loading_ResourceInterfaceFunction} start_loading
 * @property {isLoading_ResourceInterfaceFunction} isLoading
 * @property {start_ResourceInterfaceFunction} start
 * @property {stop_ResourceInterfaceFunction} stop
 * @property {import('../OnDrawingParams').onDrawingFunction} onDrawing
 * @property {RectangleGeometry} geometry

*/

/**
 * 
 */
export class ResourceCanvasElement {
    /**
     *  @type {import('../visualElement').VisualElement}
     */
    vis;
    /**
     *  @type {import('../resources/resourceInterface').ResourceInterface}
     */
    visual_resource;
    /**
    *  @type {import('../canvasElements/CanvasElement').CanvasElement|undefined}
    */
    canvasElement;
    /**
     *  @type {boolean}
     */
    isHidden;
    /**
     *  @type {import('../OnDrawingParams').onDrawingFunction}
     */
    onDrawing;
    /**
     *  @type {boolean}
     */
    isReady = false;
    /**
    *  @type {boolean}
    */
    isError;
    /**
    *  @type {number|undefined}
    */
    drawOrder;
    /**
     * 
     * @param {import('../visualElement').VisualElement} vis 
     * @param {import('../resources/resourceInterface').ResourceInterface} visual_resource 
     */
    constructor(vis, visual_resource) {
        this.vis = vis;
        this.visual_resource = visual_resource;

        if (vis.scene.application.getSetting(WebApplication.IsVerboseLoggingSettingName)) {
            //console.log("new:ResourceCanvasElement")
        }
    }
    /**
     * @return {RectangleGeometry}
     */
    get geometry() {
        if (this.vis.obj.visual_geometry) {
            return this.vis.obj.visual_geometry;
        }
        if (this.vis.obj.geometry) {
            return this.vis.obj.geometry;
        }
        if (this.visual_resource) {
            var size = this.visual_resource.pixel_size();
            var result = new RectangleGeometry();
            result.initialize(0, 0, size[0], size[1]);
            return result;
        }
        var result = new RectangleGeometry();
        result.initialize(0, 0, 0, 0);
        return result;
    }
    /**
     * @returns {import('../../../LocalServerFileCache').FileInfoType|undefined}
     */
    get url_file_info() {
        return this.visual_resource?.url_file_info;
    }
    /**
     * @returns {boolean}
     */
    get isLoadingSuccess() {

        if (this.visual_resource == undefined) {
            return false;
        }
        if (this.visual_resource.isLoaded != true) {
            return false;
        }
        this.visual_resource.checkForErrors();
        if (this.visual_resource.isError == true) {
            return false;
        }
        return true;
    }
    /**
     * 
     * @returns {string|undefined}
     */
    toSourceURLNameExt() {
        return this.visual_resource.resource_request?.toURLNameAndExtension();
    }
    /**
 * 
 * @returns {string|undefined}
 */
    toSourceURLName() {
        return this.visual_resource.toSourceURLName?.();
    }
    /**
 * 
 * @returns {string|undefined}
 */
    toSourceURL() {
        return this.canvasElement?.toSourceURL?.();
    }
    /**
 * 
 * @returns {string|undefined}
 */
    get url() {
        return this.toSourceURL();
    }
    /**
     * 
     * @param {GettingReadyCallbackCollector} gettingReadyCallbackCollector 
     */
    start_loading(gettingReadyCallbackCollector) {

        if (this.visual_resource == undefined) {
            this.isReady = true;
            return;
        }

        var promise = new Promise((resolve, reject) => {
            this.visual_resource.onVisualLoaded = (s) => {

                this.isReady = true;
                resolve("Operation successful!");
            };
            this.visual_resource.start_loading(this.vis.server_file_cache, this);
        });
        var callback = new GettingReadyCallback();
        callback.addPromise(promise);
        gettingReadyCallbackCollector.add(callback);

    }
    /**
     * 
     * @param {OnDrawingParams} params 
     */
    canvasElement_on_drawing(params) {
        this.onDrawing?.(params);
    }
    /**
     * 
     */
    start() {

        if (!this.canvasElement) {
            this.canvasElement = this.visual_resource.createCanvasElement(this);

            if (this.canvasElement) {
                this.canvasElement.onDrawing = this.canvasElement_on_drawing.bind(this);
                this.canvasElement.onError = (elm, err) => this.onElementError(elm, err);
                this.canvasElement.setHidden(this.isHidden);

                if (this.drawOrder) {
                    this.canvasElement.draw_order = this.drawOrder;
                } else {

                    this.canvasElement.draw_order = this.vis.obj.scene.draw_order
                }
            }

        }


        this.vis.resource_displayed(this.visual_resource, true);
        this.visual_resource.start(this);

        this.vis.icanvas.addElement(this.canvasElement);
    }
    /**
     * 
     * @param {import('../canvasElements/CanvasElement').CanvasElement} elm 
     * @param {string} err 
     * @returns 
     */
    onElementError(elm, err) {
        var pause_error = "The play() request was interrupted by a call to pause()"
        if (err.includes(pause_error)) {
            return;
        }

        this.visual_resource.notifyError();

        this.isError = true;
        this.vis.onResourceCanvasElementError(this);
    }
    /**
     * 
     * @param {ResourceCanvasElementInterface|undefined} next_resource_canvas_element 
     */
    stop(next_resource_canvas_element) {

        if (this.visual_resource.isLoading()) {
            this.visual_resource.cancelLoading?.();
            this.isCanceled = true;
        } else {
            this.visual_resource.stop();
        }

        this.removeCanvasElement();
    }
    // collectCanvasElements(result) {
    //     if (this.canvasElement) {
    //         result.push(this.canvasElement);
    //     }
    // }
    /**
     * 
     * @param {boolean} value 
     */
    setHidden(value) {
        this.canvasElement?.setHidden(value);
        this.isHidden = value;
    }
    /**
     * 
     * @param {number} value 
     */
    setDrawOrder(value) {
        this.drawOrder = value;
        this.canvasElement?.setDrawOrder(value);

    }
    /**
     * 
     */
    removeCanvasElement() {
        if (this.canvasElement) {
            this.vis.icanvas.removeElement(this.canvasElement);
        }
    }
    /**
     * 
     * @returns {boolean|undefined}
     */
    isLoading() {
        return this.visual_resource?.isLoading();
    }

    // toRect(scaleToCanvas = true) {
    //     return this.visual_resource?.toRect(scaleToCanvas ? this.vis.icanvas : undefined);
    // }
}
