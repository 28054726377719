//@ts-check

import { Resources } from "./resources.js";
import { Simulation } from "./simulation.js";
import { InteractiveCanvas } from "./audio_visual/interactive_canvas.js";
import { WebBrowserClient } from "./webBrowserClient.js";
import { AuthorInterface } from "./sceneAuthorInterface/authorInterface.js";
import { WebAudio } from "./audio_visual/audio/webAudio.js";
import { NullWebAudio } from './audio_visual/audio/NullWebAudio.js';
import { ExternalModules } from './modules/ExternalModules.js';
import { InternalModules } from './modules/internalModules.js';
import { SceneModules } from './modules/sceneModules.js';
import { InternalAuth } from "./auth/InternalAuth.js";
import { NoInternalAuth } from './auth/NoInternalAuth.js';
import { WebApplication } from './webApplication.js';
import { VisualElements } from './audio_visual/visual/VisualElements.js';
import { WebApplicationServer } from './webApplicationServer.js';
import { WebApplicationState } from './WebApplicationState.js';
/**
 * @type {string}
 */
let versionNumber = "0.4.1";

const auth0 = require('auth0-js');
let hash;
let response


/**
 * @callback newWebApplicationFunction
 * @param {WebApplicationState} withState 
 * @returns {WebApplication}
*/
/**
 * @callback newServerFunction
 * @param {WebApplicationState} withState 
 * @returns {WebApplicationServer}
*/

/**
 * 
 * @param {WebApplicationState} state 
 * @param {newWebApplicationFunction} newWebApplication 
 * @param {newServerFunction} newServer 
 * @param {boolean} hasExternalModules 
 * @param {boolean} hasAuth 

 */
export async function startClient(state, newWebApplication, newServer, hasExternalModules = true, hasAuth = true) {
	state.libraryVersionNumber = versionNumber;


	state.resources = new Resources();
	await state.resources.Initialize();

	state.application = newWebApplication(state);
	state.application.initialize();

	hasAuth = hasAuth && state.application.getSetting(WebApplication.IsAuthEnabledSettingName)
	state.auth = hasAuth? new InternalAuth(state) : new NoInternalAuth(state);
	await state.auth.initialize();

	state.server = newServer(state);
	await state.server.initialize();

	state.browser = new WebBrowserClient(state.resources, state.application, state.server);
	state.browser.initialize();
	state.server.setBrowser(state.browser);

	state.application.setServer(state.server);
	
	state.account = await state.server.getCustomerAccount();
	state.account.initialize();
	state.server.setClientCustomerAccount(state.account);

	state.canvas = new InteractiveCanvas(state);
	state.canvas.initialize();
	state.application.platform_canvas=state.canvas.platformCanvas;


	state.author = new AuthorInterface(state.resources, state.canvas, state.account);
	state.author.initialize();

	var hasAudio = state.application.getSetting(WebApplication.IsAudioEnabledSettingName)
	state.audio = hasAudio ? new WebAudio(state.resources, state.account) : new NullWebAudio();
	state.audio.initialize();
	state.canvas.addComponent(state.audio);

	VisualElements.initializeResourceTypes(state.application);

	state.simulation = new Simulation(state.resources, state.canvas, state.account, state.server, state.audio, state);
	await state.simulation.initialize();
	state.canvas.addComponent(state.simulation);

	if (hasExternalModules) {
		state.externalModules = new ExternalModules(state, state.resources, state.canvas, state.account, state.server, state.audio);
		state.application.initializeExternalModules(state);
		state.externalModules.initialize();
		state.internalModules = new InternalModules(state.externalModules);
		state.application.initializeInternalModules(state);
		state.internalModules.initialize();
	}
	state.sceneModules = new SceneModules();
	state.application.initializeSceneModules(state);
	state.sceneModules.initialize();


	state.author.components.push(state.browser);
	state.author.components.push(state.application);
	state.author.components.push(state.audio);
	state.author.components.push(state.account);
	//state.author.components.push(state.simulation);
	state.author.components.push(state.server);

	if (hasExternalModules) {
		state.author.components.push(state.externalModules);
		//state.author.components.push(state.internalModules);
		state.externalModules.authoringInterface = state.author;
		state.internalModules.authoringInterface = state.author;
	}


	//state.author.components.push(state.sceneModules);
	state.sceneModules.authoringInterface = state.author;

	state.canvas.addComponent(state.author);	
	state.author.diagnostics_overlay.set_profile(state.canvas.platformCanvas.name);
	state.author.diagnostics_overlay.set_brand(state.canvas.platformCanvas.brand);
	

	document.addEventListener("visibilitychange", () => {
		if (document.visibilityState == "hidden") {
			state.saveState();
		}
	});

	if (!hasAuth) {
		await state.canvas.start();
		return;
	}

	if (window.location.ancestorOrigins.length == 0 && hasAuth) {
		let webAuth = new auth0.WebAuth({
			domain: process.env.AUTH0_ISSUER_BASE_URL_ORIGIN,
			clientID: process.env.AUTH0_CLIENT_ID
		});

		let authUrl

		if (!localStorage.getItem("hash")) {
			authUrl = webAuth.client.buildAuthorizeUrl({
				clientID: process.env.AUTH0_CLIENT_ID,
				responseType: 'token id_token',
				redirectUri: process.env.REDIRECT_URI,
				state: `test`,
				nonce: 'test'
			})
		} else { hash = localStorage.getItem("hash") }

		let auth0Response;
		let userInfo;
		let accessToken;
		let clientToken;

		if (window.location.hash) {
			hash = window.location.hash;
			localStorage.setItem("hash", `${hash}`);
			if (hash.split('=')[0] == "#access_token") {
				localStorage.setItem("hash", `${hash}`);
				webAuth.parseHash({ hash: hash, state: `test`, nonce: `test` }, function (err, authResult) {
					if (err) { return console.log(err); }
					webAuth.client.userInfo(authResult.accessToken, function (err, user) {
						if (err) { return console.log(err); }
						console.log("Window Hash")
						auth0Response = user;
					});
				});
			}
			window.location.href = window.location.origin;
		} else if (hash) {
			if (hash.split('=')[0] == "#access_token") {
				await webAuth.parseHash({ hash: hash, state: `test`, nonce: `test` }, async function (err, authResult) {
					if (err) { console.log("Invalid Token"); return await state.canvas.start(); }
					else { await handleAuth(authResult?.accessToken); }

					async function handleAuth(accessToken) {
						let authReqCount = 0;
						const authInterval = setInterval(async function () {
							userInfo = await reqUser();
							authReqCount += 1;

							if (userInfo) {
								const returnObj = {
									userId: userInfo.user_id || '',
									screenname: userInfo.user_metadata?.screenname || '',
									siteSlug: "demo",
									signedIn: true,
									action: "user",
									gameReturnKey: "TEST_KEY",
									email: userInfo.email,
									emailVerified: userInfo.email_verified,
									firstName: userInfo.user_metadata?.first_name,
									lastName: userInfo.user_metadata?.last_name
								}
								window.clearInterval(authInterval);

								state.server.account = { ...state.server.account, ...returnObj }
								state.server.account.firstName = state.server.account.firstName
								state.server.account.json = { ...state.server.account.json, ...state.server.account }

								window.postMessage({ action: "auth", receiptToken: `${"auth"}-${"token"}`, userInfo }, window.location.origin);

								await state.canvas.start();
							} else if (authReqCount == 3) {
								console.log("Request Limit Reached");
								window.clearInterval(authInterval);
								await state.canvas.start();
								return;
							}
						}, 2000);

						async function reqUser() {
							try {
								await webAuth.client.userInfo(accessToken, async function (err, user) {
									if (err) {
										console.log("Invalid Token");
										return await state.canvas.start();
									} else {
										console.log("Local Hash");
										return auth0Response = user;
									}
								})
							}
							catch (err) { console.log("Invalid Token"); window.clearInterval(authInterval); return await state.canvas.start(); }
							if (auth0Response) {
								let options = {
									method: "POST",
									headers: { "Content-Type": "application/json" },
									body: JSON.stringify({
										email: auth0Response?.email
									})
								};

								try {
									response = await fetch("https://lakeside-auth.nmp.nonprod-sinclairstoryline.com/api/auth0", options).then(res => res.json());
									return response?.userData
								}
								catch (err) { return console.log(err) }
							} else { return; }
						}
					}
				});
			}
		} else if (window.location.ancestorOrigins.length != 0) { // GET USER DATA FROM PARENT
			window.addEventListener('message', async function (event) {
				const { data } = event;
				if (data.receiptToken.split("-")[0] == "user") {
					//state.server.setClientCustomerAccount(data.user)
					//state.account.name = data.user.firstName
					//state.account.json = state.server.account
				}
			});

			window.parent.postMessage({
				action: "user",          // Action key.
				receiptToken: "{receipt-token}", // Receipt validation token.
			}, window.location.ancestorOrigins[0])
		} else {
			await state.canvas.start();
		}
	}
}

