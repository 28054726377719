// https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Using_Touch_Events
// https://github.com/zingchart/zingtouch#include-the-library
import { InputCapability } from '../input/InputCapability.js';
import { WebApplication } from '../webApplication.js';

import { InteractivePlatformCanvas } from "./InteractivePlatformCanvas.js";
import { VideoFiles } from './VideoFiles.js';

export class InteractiveTVCanvas extends InteractivePlatformCanvas {

  static SonyBrandName = "Sony";
  static HisenseBrandName = "Hisense";
  static LGBrandName = "LG"
  static SamsungBrandName = "Samsung"

  constructor() {
    super();
  }

  uninitialize() {
  }

  initialize(interactiveCanvas) {
    super.initialize(interactiveCanvas);
    this.name = "TV";
    this.brand = "unknown";
  }

  apply_to_default_settings() {
    super.apply_to_default_settings();

    if (this.brand == InteractiveTVCanvas.HisenseBrandName) {
      this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.IsSBVideoEnabledSettingName, false);
      this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.IsPSVideoEnabledSettingName, true);  
    }
    else{
      this.interactiveCanvas.state.application.setDefaultSetting(WebApplication.isPreloadConnectedSceneGraphContentEnabled_SettingName, false);
    }
  }

  isTouchCapable() {
    return super.isTouchCapable();
  }

  isPlatform() {
    return false;
  }

  modifySelectionMode(selection_mode) {

    // var r = super.createDefaultSelectionMode();
    if (this.brand == InteractiveTVCanvas.SonyBrandName || this.brand == InteractiveTVCanvas.HisenseBrandName) {
      // return new SceneInteractionMode(sim);
      selection_mode.input_capability = InputCapability.wasd;
      //  return r;
    }
    // else {
    //   return super.createDefaultSelectionMode();
    // }
    // return r;
  }

  // select_video_content(set) {
  //   var videos = new VideoFiles(set);
  //   return videos.getVideoPathByQuality(0);
  // }

  // isLikelyTVBrowser() {
  //   const userAgent = navigator.userAgent.toLowerCase();

  //   const tvKeywords = [
  //     'smarttv',
  //     'googletv',
  //     'appletv',
  //     'firetv',
  //     'lgwebostv',
  //     'samsungtv',
  //     'viera',
  //     'netcast',
  //   ];

  //   return tvKeywords.some(keyword => userAgent.includes(keyword));
  // }

}


export class InteractiveBrowserTVCanvas extends InteractiveTVCanvas {

  constructor() {
    super();
  }

  isPlatform() {
    return InteractiveBrowserTVCanvas.isBrowserTV();
  }

  initialize(interactiveCanvas) {
    super.initialize(interactiveCanvas);
    this.name = "TV Browser";
    if (navigator.userAgent.includes("WebOS;")) {
      this.brand = InteractiveTVCanvas.LGBrandName;
    }
  }

  uninitialize() {
    super.uninitialize();
  }

  static isBrowserTV() {
    var found = navigator.userAgent.includes("SmartTV");
    return found;
  }
}

export class InteractiveATSC3TVCanvas extends InteractiveTVCanvas {

  constructor() {
    super();
  }

  isPlatform() {
    return InteractiveATSC3TVCanvas.isATSC3TV();
  }

  initialize(interactiveCanvas) {
    super.initialize(interactiveCanvas);
    this.name = "ATSC 3.0 TV";
    if (navigator.userAgent.includes("Samsung")) {
      this.brand = InteractiveTVCanvas.setDefaultSetting;
    } else if (navigator.userAgent.includes("Sony")) {
      this.brand = InteractiveTVCanvas.SonyBrandName;
    } else if (navigator.userAgent.includes("Hisense")) {
      this.brand = InteractiveTVCanvas.HisenseBrandName;
    }
  }

  uninitialize() {
    super.uninitialize();
  }

  static isATSC3TV() {
    var atsc3 = navigator.userAgent.includes("ATSC3/");

    return atsc3;
  }
}
