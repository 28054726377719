import { ValueChangedSubscriptions } from './ValueChangedSubscriptions';
import { WebApplication } from './webApplication';

export class WebApplicationSettings {

  static isUXSceneEnabled_SettingName = "User Interface Enabled";

  static MinSuffix = "_min";
  static MaxSuffix = "_max";

  json;
  setting_subscriptions = {};

  constructor() {
  }

  initialize() {
    this.json = {};
    this.json.settings = {};
    this.json.default = {};
    this.json.default.settings = {};
    this.json.ui = {};
    this.json.ui.settings = {};
  }

  addSettings() {
    this.addSettingToJson(WebApplicationSettings.isUXSceneEnabled_SettingName, true);

    this.addSettingToJson(WebApplication.isAudioVisualScriptsEnabled_SettingName, WebApplication.isAudioVisualScriptsEnabled_SettingDefaultValue);

    this.addSettingToJson(WebApplication.isPreloadConnectedSceneGraphContentEnabled_SettingName, WebApplication.isPreloadConnectedSceneGraphContentEnabled_SettingDefaultValue);

    this.addSettingToJson(WebApplication.isShowConsoleLogOverlaySettingName, WebApplication.isShowConsoleLogOverlaySettingDefaultValue);
    this.addSettingToJson(WebApplication.isConsoleLogOverlayTimeoutSettingName, WebApplication.isConsoleLogOverlayTimeoutSettingDefaultValue);

    this.addSettingToJson(WebApplication.isShowDebugOverlaySettingName, WebApplication.isShowDebugOverlaySettingDefaultValue);
    this.addSettingToJson(WebApplication.isShowDetailedDebugOverlaySettingName, WebApplication.isShowDetailedDebugOverlaySettingDefaultValue);
    this.addSettingToJson(WebApplication.isShowMemoryDiagnosticsOverlaySettingName, WebApplication.isShowMemoryDiagnosticsOverlaySettingDefaultValue);
    this.addSettingToJson(WebApplication.isDebugOverlayTimeoutSettingName, WebApplication.isDebugOverlayTimeoutSettingDefaultValue);

    this.addSettingToJson(WebApplication.isLoadApplicationLocalStorageSettingName, WebApplication.isLoadApplicationLocalStorageSettingDefaultValue);
    this.addSettingToJson(WebApplication.isSaveApplicationLocalStorageSettingName, WebApplication.isSaveApplicationLocalStorageSettingDefaultValue);
    this.addSettingToJson(WebApplication.isDeleteBeforeSaveApplicationLocalStorageSettingName, WebApplication.isDeleteBeforeSaveApplicationLocalStorageSettingDefaultValue);


    this.addSettingToJson(WebApplication.isLoadAccountLocalStorageSettingName, WebApplication.isLoadAccountLocalStorageSettingDefaultValue);
    this.addSettingToJson(WebApplication.isSaveAccountLocalStorageSettingName, WebApplication.isSaveAccountLocalStorageSettingDefaultValue);
    this.addSettingToJson(WebApplication.isPreCacheAssetsSettingName, WebApplication.isPreCacheAssetsSettingDefaultValue);
    this.addSettingToJson(WebApplication.isUseRESTSettingName, WebApplication.isUseRESTSettingDefaultValue);

    this.addSettingToJson(WebApplication.IsVerboseLoggingSettingName, WebApplication.IsVerboseLoggingSettingDefaultValue);

    this.addSettingToJson(WebApplication.IsVideoEnabledSettingName, WebApplication.IsVideoEnabledSettingDefaultValue);

    this.addSettingToJson(WebApplication.IsDBVideoEnabledSettingName, WebApplication.IsDBVideoEnabledSettingDefaultValue);
    this.addSettingToJson(WebApplication.IsSBVideoEnabledSettingName, WebApplication.IsSBVideoEnabledSettingDefaultValue);
    this.addSettingToJson(WebApplication.IsPSVideoEnabledSettingName, WebApplication.IsPSVideoEnabledSettingDefaultValue);

    this.addSettingToJson(WebApplication.IsAllCanvasVideosSettingName, WebApplication.IsAllCanvasVideosSettingDefaultValue);
    this.addSettingToJson(WebApplication.VideoFramesPerSecondSettingName, WebApplication.VideoFramesPerSecondSettingDefaultValue);

    this.addSettingToJson(WebApplication.IsAuthEnabledSettingName, WebApplication.IsAuthEnabledSettingDefaultValue);
    this.addSettingToJson(WebApplication.IsAudioEnabledSettingName, WebApplication.IsAudioEnabledSettingDefaultValue);
    this.addSettingToJson(WebApplication.IsWebpEnabledSettingName, WebApplication.IsWebpEnabledSettingDefaultValue);
    this.addSettingToJson(WebApplication.isVideoPlayingTimeoutSettingName, WebApplication.isVideoPlayingTimeoutSettingDefaultValue);

    //this.addSettingToJson(WebApplication.IsWebpWebAssemblyEnabledSettingName, WebApplication.IsWebpWebAssemblyEnabledSettingDefaultValue);
  }


  addSettingToJson(name, defaultValue = undefined, minValue = undefined, maxValue = undefined) {

    if (!this.json.default.settings.hasOwnProperty(name)) {
      this.json.default.settings[name] = defaultValue;
      if (minValue != undefined) {
        this.json.default.settings[name + WebApplication.MinPrefix] = minValue;
      }
      if (maxValue != undefined) {
        this.json.default.settings[name + WebApplication.MaxSuffix] = maxValue;
      }
    }
    if (!this.json.ui.settings.hasOwnProperty(name)) {
      this.json.ui.settings[name] = {};
    }
  }

  addSettingSubscription(name, onChanged) {
    if (name == undefined || onChanged == undefined) {
      return;
    }
    ValueChangedSubscriptions.addToObjectArrayPropertyByName(this.setting_subscriptions, name, onChanged);
  }

  removeSettingSubscription(name, onChanged) {
    if (name == undefined || onchange == undefined) {
      return;
    }
    ValueChangedSubscriptions.removeFromObjectArrayPropertyByName(this.setting_subscriptions, name, onChanged);
  }

  setSetting(property, v, isNotify = true) {
    this.json.settings[property] = v;
    if (this.setting_subscriptions && isNotify == true) {
      ValueChangedSubscriptions.updateValueByName(this.setting_subscriptions, property, v);
    }
  }

  setDefaultSetting(property, v) {
    this.json.default.settings[property] = v;
  }

  toggleBooleanSetting(name) {
    var s = this.getSetting(name);
    if (s == undefined) {
      return;
    }

    this.setSetting(name, !s);
  }

  getSetting(name, onChanged = undefined) {
    if (this.json.settings.hasOwnProperty(name)) {
      return this.json.settings[name];
    }
    if (this.json.default.settings.hasOwnProperty(name)) {
      return this.json.default.settings[name];
    }
    return undefined;
  }

  get settings_json() {
    return this.json.settings;
  }

  loadFromStorage(base_json) {

    if (base_json.settings) {
      this.json.settings = base_json.settings;
    } else {
      this.json.settings = {};
    }
  }

  saveToStorage(base_json) {
    base_json.settings = this.json.settings;
  }
}
