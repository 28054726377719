
import { SingleBufferedSharedVideoResourceCanvasElement } from '../resourceCanvasElements/SingleBufferedSharedVideoResourceCanvasElement.js';
import { FileResourceRequest } from '../../../resources/FileResourceRequest.js';

import { FileResource } from '../../../resources/FileResource.js';
let c2 = require("c2.js");

export class VideoFileSingleBufferedResource extends FileResource {

  static requestMp4VideoResource(request) {
    return new VideoFileSingleBufferedResource(request);
  }
  static disposeMp4VideoResource(resource) { }

  static canCreateResourceFromJson(json, property) {
    return property == "video" && json[property] !== undefined;

  }
  static canCreateResourceFromJsonObject(json) {
    return json["video"] !== undefined;
  }
  static requestResource(name, path, path2, webapp) {
    let request = new FileResourceRequest(path, name, "", "", path2, true, false, false, webapp);

    request.setExtension(".mp4");
    request.setCategoryPath("visuals/video/");
    request.updateFullpath();

    var file_entries = webapp.server.server_file_cache.get_additional_manifest_entries_for_file_request(request);
    if (file_entries?.length > 0) {

      var names = file_entries.map(each => FileResource.getFullpathFilenameWithoutExtension(each));
      var selection = webapp.platform_canvas.select_video_content(names);
      if (selection) {
        request.change_to_filename(selection);
      }
    }

    var resource = this.requestMp4VideoResource(request);

    if (resource) {
      resource.url = resource.resource_request.toUrlPath();
      resource.server_file_cache = webapp.server.server_file_cache;
      resource.url_file_info = resource.server_file_cache.server_asset_lookup[resource.url];
      resource.type = "video";
    }

    return resource;
  }
  static createResourceFromJson(json, property, path, path2, webapp) {
    if (this.canCreateResourceFromJson(json, property, webapp)) {
      let result = this.requestResource(json.video, path, path2, webapp);
      result.event_playedOnce = json["video.event.playedOnce"];
      result.isLoop = json["video.loop"];
      result.isMuted = json["video.muted"];
      result.isAutoPlay = json["video.autoplay"];

      return result;
    }
  }

  resource_element;
  resource_request;
  loading_promise;
  isError;
  isLoaded = true;

  event_playedOnce;
  isLoop;
  isMuted;
  isAutoPlay;
  onRunAction;
  onVisualLoaded;
  server_file_cache;

  response;

  download_diagnostics;

  constructor(resource_request) {
    super();
    this.resource_request = resource_request;
  }

  toSourceURL() {
    return this.resource_request.toUrlPath();
  }
  toSourceURLName() {
    return this.resource_request.name;
  }
  createResourceCanvasElement(vis) {
    return new SingleBufferedSharedVideoResourceCanvasElement(vis, this);
  }

  unapply_property_values_to_resource_element() {
    this.resource_element.muted = true;
    this.resource_element.loop = true;
  }
  unapply_event_values_to_resource_element() {
  }

  apply_property_values_to_resource_element() {


    if (this.isAutoPlay !== undefined) {
    } else {
      this.isAutoPlay = true;
    }

    if (this.isMuted !== undefined) {
      this.resource_element.muted = this.isMuted == true;
    } else {
      this.resource_element.muted = true;
    }

    if (this.isLoop !== undefined) {
      this.resource_element.loop = this.isLoop == true;
    }
    else {
      this.resource_element.loop = true;
    }
  }

  on_buffer_ended(index, buffer) {
    if (this.event_playedOnce !== undefined) {
      this.onRunAction?.(this.event_playedOnce);
    }
  }

  cancelLoading() {

  }

  start(buffer_video_element) {
    this.resource_element = buffer_video_element;
    var url = this.resource_request.toUrlPath();

    this.apply_property_values_to_resource_element();
    this.resource_element.src = url;
    this.resource_element.currentTime = 0;

    if (this.isAutoPlay) {
      //console.log("playing video element");
      this.resource_element.play();
    }
  }

  notifyError() {
    this.isError = true;
  }
  stop() {
    if (this.isError) {

    }
  }
  initialize() {

  }

  isLoading() {
    return false;
  }

  getLoadingPromise() {
    return undefined;
  }

  checkForErrors() {

    return false
  }

  // toRect(icanvas = undefined) {

  //   var video_width = this.url_file_info.width;
  //   var video_height = this.url_file_info.height

  //   if (icanvas) {
  //     var canvas_w = icanvas.get_width();
  //     var canvas_h = icanvas.get_height();

  //     if (video_width == 0 || video_height == 0) {
  //       video_width = canvas_w;
  //       video_height = canvas_h;
  //     }

  //     var hRatio = canvas_w / video_width;
  //     var vRatio = canvas_h / video_height;
  //     var ratio = Math.min(hRatio, vRatio);
  //     var result = new c2.Rect(0, 0, video_width * ratio, video_height * ratio);
  //     if (result.w == NaN) {

  //     }
  //     return result;
  //   }

  //   var result = new c2.Rect(0, 0, video_width, video_height);
  //   return result;
  // }

  createCanvasElement(resource_canvas_element) {
    return undefined;
  }

  pixel_size() {
    var video_width = this.url_file_info.width;
    var video_height = this.url_file_info.height
    return [video_width, video_height];
  }
}
