export class CanvasElement {
  is_invalidating_draw;
  resource;
  draw_order;
  isHidden;
  opacity;
  icanvas;
  autoInvalidateFrequencyPerSecond;
  isFirstTimeCanvasAdd=true;
  errorLog = [];
  onError;

  warningLog;
  onWarning;
  onDrawing;

  constructor(resource = undefined,resource_canvas_element=undefined, is_invalidating_draw = true) {
    this.resource = resource;
    this.is_invalidating_draw = is_invalidating_draw;
    this.draw_order = 1;
    this.resource_canvas_element=resource_canvas_element;
    console.log("new:CanvasElement");
  }

  addError(error, notify = true, isLog=true, returnValue=false) {
    this.errorLog.push(error);

    if(isLog){
    console.error(error);
    }

    if (notify) {
      this.onError?.(this, error);
    }

    return returnValue;
  }

  onCanvasResized() { }
  draw() { }
  addedToInteractiveCanvas(icanvas) {
    this.icanvas = icanvas;
    this.icanvas.addAutoInvalidateFrequencyPerSecond(this, this.autoInvalidateFrequencyPerSecond);
    this.addingToInteractiveCanvas();
    this.isFirstTimeCanvasAdd=false;
  }
  removedFromInteractiveCanvas() {
    this.icanvas?.removeAutoInvalidateFrequencyPerSecond(this);

    this.removeingFromInteractiveCanvas();
    this.icanvas = undefined;
  
  }
  addingToInteractiveCanvas() {

  }
  removeingFromInteractiveCanvas() {

  }

  invalidate() {
    this.is_invalidating_draw = true;
  }
  validate() {
    this.is_invalidating_draw = false;
  }

  setHidden(value) {
    if (value == this.isHidden) {
      return;
    }
    if (value) {
      this.isHidden = true;
      // console.log("t");
    } else {
      this.isHidden = false;
      // console.log("f");
    }

    this.isHidden = value;
    this.invalidate();
  }

  isLoading() {
    return this.resource?.isLoading();
  }

  getFirstLoadingPromise() {
    return this.resource?.getLoadingPromise();
  }
  setDrawOrder(value){
    this.draw_order=value;
  }

  setAutoInvalidateFrequencyPerSecond(value) {
    this.autoInvalidateFrequencyPerSecond = value;
    this.icanvas?.removeAutoInvalidateFrequencyPerSecond(this);
    this.icanvas?.addAutoInvalidateFrequencyPerSecond(this, this.autoInvalidateFrequencyPerSecond);
  }
}
