export class Canvas {
  element;
}

export class CanvasStack {
  canvas_stack = [];
  icanvas;
  constructor(icanvas) {
    this.icanvas = icanvas;
  }
  
  push(canvas) {
    this.canvas_stack.push(canvas);
    this.icanvas.viewport.appendChild(canvas.element);
  }

  pop(canvas = undefined) {

    var popped;

    if (canvas != undefined) {
      let index = this.canvas_stack.indexOf(canvas);

      if (index < 0) {
        console.warn(`canvas pop not found`);
        return;
      }
      popped = this.canvas_stack[index];
      this.canvas_stack.splice(index, 1);
    } else {
      popped = this.canvas_stack.pop();
    }

    this.icanvas.viewport.removeChild(popped.element);
  }
}
