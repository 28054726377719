//@ts-check

import { VisualElement } from './visualElement';
import { VisualElements } from './VisualElements';
/**
 * 
 */
export class AudioVisualPlaybackQueueItem {
    /**
     * 
     * @returns {boolean}
     */
    isLooping;
    /**
     * 
     * @returns {Array.<object>}
     */
    stems;
    /**
     * 
     * @returns {Array.<VisualElement>}
     */
    visual_elements;
    /**
     * 
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj 
     */
    start(obj) {
        for (const each of this.visual_elements || []) {
            each.start();
        }
    }
    /**
     * 
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj 
     * @param {import('./resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface} next_resource_canvas_element 
     */
    stop(obj, next_resource_canvas_element) {
        for (const each of this.visual_elements || []) {
            each.stop(next_resource_canvas_element);
        }
    }
    /**
     * 
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj 
     * @param {import('../../sceneGraph/GettingReadyCallbackCollector').GettingReadyCallbackCollector} gettingReadyCallbackCollector 
     */
    start_loading(obj, gettingReadyCallbackCollector) {

        this.visual_elements = [];

        if (this.stems) {
            for (const each of this.stems) {
                if (VisualElements.VideoVisualResourceType?.canCreateResourceFromJsonObject(each)) {

                    var visual_element = new VisualElement(obj, each, obj.getResourcePath());

                    visual_element.startVisualContentGetReady(gettingReadyCallbackCollector);

                    this.visual_elements.push(visual_element);
                }
            }
        }
    }
}
/**
 * 
 */
export class AudioVisualPlaybackQueue {
    /**
     * 
     * @type {boolean}
     */
    isStarted = false;
    /**
     * 
     * @type {AudioVisualPlaybackQueueItem}
     */
    active_item;
    /**
     * 
     * @type {Array.<AudioVisualPlaybackQueueItem>}
     */
    item_queue = [];
    /**
     * 
     * @param {Array.<object>} stems 
     */
    set_looping_stems(stems) {
        var item = new AudioVisualPlaybackQueueItem();
        item.isLooping = true;
        item.stems = stems;

        if (!this.isStarted) {
            this.active_item = item;
        } else {
            this.item_queue = [item];
            this.beginPlayingNextInQueue();
        }
    }
    /**
     * 
     */
    beginPlayingNextInQueue() {


    }
    /**
     * 
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj 
     * @param {import('../../sceneGraph/GettingReadyCallbackCollector').GettingReadyCallbackCollector} gettingReadyCallbackCollector 
     */
    start_loading(obj, gettingReadyCallbackCollector) {
        this.active_item?.start_loading(obj, gettingReadyCallbackCollector);
    }
    /**
      * 
      * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj 
      */
    start(obj) {
        this.active_item?.start(obj);
        this.isStarted = true;
    }
    /**
     * 
     * @param {import('../../sceneGraph/scene').SceneObjectInterface} obj 
     * @param {import('./resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface} next_resource_canvas_element 
     */
    stop(obj, next_resource_canvas_element) {
        this.active_item?.stop(obj, next_resource_canvas_element);
        this.isStarted = false;
    }
}
