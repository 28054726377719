//https://sinclairdigital.atlassian.net/wiki/spaces/DL/pages/574390466/UX+Data+Loading+-+For+TV

import { Actions } from '../sceneGraph/actions/actions';
import { Simulation } from '../simulation';
import { InputCapability } from './InputCapability';
import { KeyCodes } from './KeyCodes';

export class SelectionMode {
    simulation;
    keybindings;
    ux_image_path;
    key_down_bindings = [];
    key_up_bindings = [];
    //key_hold_down_bindings = [];
    //key_hold_times = {};
    input_capability = InputCapability.wasd_okay_back_home;

    constructor() {
    }

    helpText() {
        return "";
    }
    initialize(simulation) {
        this.simulation = simulation;
        this.simulation.icanvas.platformCanvas?.modifySelectionMode(this);
    }

    transitionState(new_state) {
        this.simulation.selectionMode.stopState();
        this.simulation.selectionMode = new_state;
        this.simulation.selectionMode.initialize(this.simulation);
        this.simulation.selectionMode.startState();
    }

    stopState() {
        // var uxScene = this.simulation.sceneGraphNode.findSceneByName("ux-scene");
        // var icon = uxScene.findInteractiveByName(this.ux_image_path, false);
        // icon?.area.geometry.setScale(0);
    }

    startState() {
        // var uxScene = this.simulation.sceneGraphNode.findSceneByName("ux-scene");
        // var icon = uxScene.findInteractiveByName(this.ux_image_path, false);
        // if (icon?.area.geometry) {
        //     icon.area.geometry.setScale(1.0);

        // }
    }

    onInteractiveInputKeydown(ievent, interactiveInput) {
        if (this.keybindings == undefined) {
            return;
        }

        var keybindings_verb = this.keybindings[interactiveInput.verb];
        if (keybindings_verb != undefined) {
            var keys = keybindings_verb[interactiveInput.verb_value];

            let key = ievent.e.keyCode;

            console.log(`Key pressed: ${key}`);

            for (let each in keys) {
                if (keys[each] == key) {
                    interactiveInput.interactive.onInteractiveInput(ievent, interactiveInput);
                    ievent.preventDefaultAndStopPropagation();
                    break;
                }
            }
        }
    }

    onInteractiveInputKeyup(ievent, interactiveInput) {

    }

    isSceneInteractionMode() {
        return false;
    }

    check_keybindings(array, ievent) {
        for (const each_item of array) {
            var each_key = each_item[0];
            var each = each_item[1];

            if (Array.isArray(each_key)) {
                for (const each_key_array_item of each_key) {
                    if (ievent.e.keyCode == each_key_array_item) {
                        ievent.preventDefaultAndStopPropagation();
                        each();
                        this.simulation.getUXScene()?.sceneInteractiveLayer.onActivity(false);
                        return true;
                    }
                }
            } else {
                if (ievent.e.keyCode == each_key) {
                    ievent.preventDefaultAndStopPropagation();
                    each();
                    this.simulation.getUXScene()?.sceneInteractiveLayer.onActivity(false);
                    return true;
                }
            }
        }
        return false;
    }

    keyup(icanvas, ievent) {
        this.check_keybindings(this.key_up_bindings, ievent);

        // var whenDown = this.key_hold_times[ievent.e.keyCode];

        // if (whenDown != undefined) {
        //     var hold_duration = 600;
        //     var now = Date.now();
        //     var delta = now - whenDown ;
        //   //  console.log(`${} ${}`);
        //     if (delta> hold_duration) {
        //         var found = this.check_keybindings(this.key_hold_down_bindings, ievent);
        //     }

        //     this.key_hold_times[ievent.e.keyCode] = undefined;
        // }
    }

    keydown(icanvas, ievent) {
        this.check_keybindings(this.key_down_bindings, ievent);

        // if(this.key_hold_times[ievent.e.keyCode]==undefined){
        // this.key_hold_times[ievent.e.keyCode] = Date.now();

        // }
    }
}

export class NavigationMode extends SelectionMode {
    constructor() {
        super();
        this.ux_image_path = "icon-navigation-mode";
    }

    change_to_interaction_mode() {

        var scene = this.simulation.getActiveScene();
        var override_action = scene?.json[NavigationMode.noSelectionModePropertyName];

        if (override_action) {
            var actions = new Actions();
            actions.addActionFromJson(override_action, this.simulation.application);
            actions.runActions(scene);
        } else {

            this.transitionState(new SceneInteractionMode());
        }

    }

    helpText() {
        var r = `Navigation mode
  up: move forward
  left: turn left
  down: move backward
  right: turn right
`;

        if (this.input_capability == InputCapability.wasd_okay_back_home) {
            r += `  okay: interactive mode
  back: previous scene
`;
        } else {
            r += `  back: interactive mode`;
        }

        return r;
    }

    initialize(simulation) {
        super.initialize(simulation);

        this.keybindings = {
            rotate: {
                left: [KeyCodes.a, KeyCodes.ArrowLeft],
                right: [KeyCodes.d, KeyCodes.ArrowRight]
            },
            move: {
                forward: [KeyCodes.w, KeyCodes.ArrowUp],
                back: [KeyCodes.s, KeyCodes.ArrowDown],

            }
        };

        if (this.input_capability == InputCapability.wasd_okay_back_home) {

            this.key_down_bindings.push([KeyCodes.Home, () => {
                var actions = new Actions();
                actions.addActionFromJson(Actions.newJsonFromVerb('home'), this.simulation.application);
                actions.runActions(this.simulation.getActiveScene());
            }]);


            this.key_down_bindings.push([KeyCodes.Enter, () => {
                this.change_to_interaction_mode();
            }]);
            this.key_down_bindings.push([KeyCodes.Backspaces, () => {
                var actions = new Actions();
                actions.addActionFromJson(Actions.newJsonFromVerb('back'), this.simulation.application);
                actions.runActions(this.simulation.getActiveScene());
            }]);

        }
        else {

            this.key_down_bindings.push([KeyCodes.Backspaces, () => {
                this.change_to_interaction_mode();
            }]);

            // this.key_hold_down_bindings.push([KeyCodes.Downs, () => {
            //     this.change_to_interaction_mode();
            // }]);
        }
    }

    static noSelectionModePropertyName = "replace_selection_mode_transition_with_action";
}

export class SceneInteractionMode extends SelectionMode {

    constructor() {
        super();
        this.ux_image_path = "icon-interactive-mode";
    }

    run_selected_actions() {

        var selected = this.simulation.getSelectedSceneInteractive();
        selected?.runActions();
    }
    exit_mode() {
        this.transitionState(new NavigationMode());
    }
    helpText() {
        var r = `Interaction mode
`;

        if (this.input_capability == InputCapability.wasd_okay_back_home) {
            r += `  up: select previous
  left: select previous
  down: select next
  right: select next
  okay: activate selection
  back: navigation mode`;
        } else {
            r += `  up: activate selection
  left: select previous
  down: select next
  right: select next
  back: navigation mode`;
        }

        return r;
    }
    initialize(simulation,) {
        super.initialize(simulation);

        this.key_down_bindings.push([KeyCodes.RightsDowns, () => {
            if(this.simulation.is_ux_faded_out()){
                return;
            }
            this.simulation.selectNextSceneInteractive(1);
        }]);

        if (this.input_capability == InputCapability.wasd_okay_back_home) {

            this.key_down_bindings.push([KeyCodes.Home, () => {
                var actions = new Actions();
                actions.addActionFromJson(Actions.newJsonFromVerb('home'), this.simulation.application);
                actions.runActions(this.simulation.getActiveScene());
            }]);

            this.key_down_bindings.push([KeyCodes.Backspaces, () => {
                this.exit_mode();
            }]);

            this.key_down_bindings.push([KeyCodes.Enter, () => {
                this.run_selected_actions();
            }]);


            this.key_down_bindings.push([KeyCodes.LeftsUps, () => {
                if(this.simulation.is_ux_faded_out()){
                    return;
                }
                this.simulation.selectNextSceneInteractive(-1);
            }]);

        }
        else if (this.input_capability == InputCapability.wasd) {
            this.key_down_bindings.push([KeyCodes.Ups, () => {
                this.run_selected_actions();
            }]);

            // this.key_down_bindings.push([KeyCodes.Downs, () => {
            //     this.exit_mode();
            // }]);

            // this.key_down_bindings.push([KeyCodes.Rights, () => {

            //     this.simulation.selectNextSceneInteractive(1);
            // }]);

            this.key_down_bindings.push([KeyCodes.Lefts, () => {
                if(this.simulation.is_ux_faded_out()){
                    return;
                }
                this.simulation.selectNextSceneInteractive(-1);
            }]);

            this.key_down_bindings.push([KeyCodes.Backspaces, () => {
                this.exit_mode();
            }]);
        }
    }


    stopState() {
        super.stopState();
        this.simulation.sceneGraphNode.selectNone();
    }

    startState() {
        super.startState();
        this.simulation.sceneGraphNode.selectNone();

        var scene = this.simulation.getActiveScene();
        var interactives = scene.sceneInteractiveLayerInteractives;
        interactives.selectDefaultOrFirst();
    }

    isSceneInteractionMode() {
        return true;
    }
}
