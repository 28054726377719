export class SceneChangeRequest {
  isPushNavigationHistory;
  location;
  //replace_location;
  error;
  loading_promise;
  startedAt;
  isCancled;
  isPrimary;
  isCompleted;
  callback_collector;
  callback_collector_promise;

  constructor(location, isPushNavigationHistory = true) {
    this.location = location;
    this.isPushNavigationHistory = isPushNavigationHistory;
  }
}
